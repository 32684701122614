import React, { useContext, useEffect} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import './FullScreenScanner.css'
import { QrReader } from 'react-qr-reader';
import { UserContext } from "../../context/userContext";
import GoBack from '../goBack/GoBack';


const FullScreenScanner = () => {
    const {index} = useParams()
    const { setSendAddress } = useContext(UserContext);
    const navigate = useNavigate()
    
  useEffect(() => {
    setSendAddress('')
  },[])

  return (
    <div >
        <GoBack value='DTC Details' link='/my-dtcs'/>
        <div className='wrapper-qr'>
            <QrReader 
            constraints={{ facingMode: 'environment' }}
            className="scannerFullScreen"
            onResult={(result, error) => {
                if (!!result) {
                setSendAddress(result?.text);
                navigate(`/my-dtcs/${index}`)
                QrReader.stop()
                }
    
                if (!!error) {
                console.info(error);
                }
            }}
            />
        </div>
    </div>
  )
}

export default FullScreenScanner