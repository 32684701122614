import React, { useContext,  useState, useEffect } from "react";
import "./Agenda.css";
import '../home/Home.css';
import { Container, Stack } from "react-bootstrap";
import { UserContext } from "../../context/userContext";
import { Link } from "react-router-dom";
import RecentDestinations from "../recent-destinations/recent-destinations";
import Tooltip from '@mui/material/Tooltip';
import Navigation from "../navigation/Navigation";
import GoBack from "../goBack/GoBack";
import FooterOpciones from "../footer-completo/footer-opciones";
import Modales from "../../utils/modal/modal";
import AddContact from '../../assets/profile-add.png';
import GridLoader from "react-spinners/GridLoader";

const Agenda = () => {
  const { currentAccount, contacts, openConfirmationModal, retrieveContacts } = useContext(UserContext);
  const [initialLoading, setInitialLoading] = useState(false);
  const [modalIsShown, setModalIsShown] = useState(false)
  const [id, setId] = useState('')
  const [open, setOpen] = useState(false)
  const [contactIndex, setContactIndex] = useState(0)

  function handleCopy(address, index) {
    setContactIndex(index)
    setOpen(true)
    navigator.clipboard.writeText(address);
    // alert('copied');
    setTimeout(()=>{
      setOpen(false)
    }, 600)
  }

  const handleDelete = async(contactID) => {
    
    setId(contactID)
    setModalIsShown(true)
    
  }

  // comunication with modal submit
  const modalToParent = () => {
    setModalIsShown(false);
  };

  useEffect(() => {
    setInitialLoading(true)    
    
    setTimeout(() => {
      setInitialLoading(false)
    }, 500)
    
  },[contacts])


  return (
    <>
      <div className='height-all-less-footer'>
        <Navigation />
        <GoBack value="Agenda" link='/home' />
        {initialLoading ? (<GridLoader color='#E9E9E9' style={{ position: 'fixed', margin: 'auto', inset: '45%' }} />) : <><RecentDestinations param="agenda" />
        <Container>
          <h5 className="titleh5">Contacts</h5>
          
          
              <Stack className="">
                 {contacts?.map((contact, index) => (
                  contact.attributes?.isActive === true &&
                  <div className="total-contact" key={contact.id}>
                    <div className="first-part-contact">
                      <div className="circle">{contact.attributes.contactName.slice(0, 2)}</div>
                      <div className="name-address">
                        <p>{contact.attributes.contactName}</p>
                        <p><Tooltip title="Copied" open={contactIndex === index && open } key={contact.id} disableFocusListener disableHoverListener disableTouchListener>
                          <i className="copyIcon fa-regular fa-clone ml-2" onClick={(e) => handleCopy(contact.attributes.contactAddress, index)}></i>
                          </Tooltip>
                          <Tooltip title="Copy Address">
                            <span onClick={(e) => handleCopy(contact.attributes.contactAddress)}>{contact.attributes.contactAddress.slice(0,7)}...{contact.attributes.contactAddress.slice(35)}</span>
                          </Tooltip></p>
                      </div>
                    </div>
                    <div className="trash-edit">
                      <Tooltip title="Delete"><i className="fa-solid fa-trash-can" onClick={(e) => handleDelete(contact.id)}></i></Tooltip>
                      {/* <Tooltip title="Edit"><i className="fa-solid fa-user-pen"></i></Tooltip> */}
                    </div>
                  </div>)).reverse()
                } 
              </Stack>

              {contacts.length === 0 && 
          <div className='no-contacts'>
          <Tooltip title='Add Contact'>
                <Link to="/add-contact">
                  <div className="circle addFirstContact">
                    <img src={AddContact} alt='add contact' className='img-add-contact'/>
                  </div>
                </Link>
              </Tooltip>
              <p style={{fontSize: '24px', fontWeight: '400'}}>Add Contact</p>
          </div>}


              {(modalIsShown) && <Modales modalToParent={modalToParent}
                
                _function={"Delete_Contact"}
                _id={id}
                _title={"Delete Contact"}
                _text={"¿Delete this contact?"}
              ></Modales>}


              {(openConfirmationModal) &&
                <Modales 
                  modalToParent={modalToParent}
                  action={"Contact Deleted"}
                  _from={currentAccount}
                  _title={"The contact was deleted succesfully"}
                  _text={"The contact was deleted succesfully."}
                ></Modales>}

        </Container>
        </>
        }
      </div>
      <FooterOpciones />
    </>
  );
};

export default Agenda;
