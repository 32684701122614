import * as React from 'react'
import "./AttrChip.css"
import { Typography } from '@mui/material'

const AttrChip = ({ prop, value }) => {
    return (
        <div className="chip-container">
            <Typography component="div" className="chip-title">{prop}</Typography>
            <Typography component="div" className="chip-value">{value}</Typography>
        </div>
    )
}

export default AttrChip