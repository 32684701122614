import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import "./NftAmigo.css";
import CardNFT from '../../utils/CardNFT/CardNFT'
import CardLoadMetadata from '../../utils/CardLoadMetadata/CardLoadMetadata';
import { Container, Button, Row, Col } from "react-bootstrap";
import Navigation from "../navigation/Navigation";
import usePagination from "../../utils/pagination/pagination"
import GoBack from "../goBack/GoBack";
import FooterOpciones from '../footer-completo/footer-opciones'
import GridLoader from "react-spinners/GridLoader";
import Pagination from '@mui/material/Pagination';
import NFTBigger from '../../assets/noDTCtransparent.png'
import Moralis from 'moralis';
import { useMoralisWeb3Api } from "react-moralis";

//context 
import { UserContext } from "../../context/userContext";

const NftAmigoAddress = () => {
  const { provider } = useContext(UserContext);
  const Web3Api = useMoralisWeb3Api();
  const urlParams = useParams()
  const url = urlParams["*"]
  let { addressSearched } = useParams();

  //trim the URL from useParams hook
  const fields = url.split('/');
  const index = fields[0] + '/' + fields[1];
  
  const [initialLoading, setInitialLoading] = useState(true);
  const [listOfNft, setListOfNft] = useState('')
  const [nfts, setNfts] = useState('')
  const [address, setAddress] = useState('')
  let navigate = useNavigate();
  
  // Pagination
  const [page, setPage] = React.useState(1);
  const limit = 6;
  const _DATA = usePagination(listOfNft, limit);
  
  const [paginationLength, setPaginationLength] = useState(1);

  const handlePaginationChange = (event, value) =>{
    setInitialLoading(true)
    setPage(value);
    _DATA.jump(value)
    setInitialLoading(false)
  }

  useEffect(() => {
    setTimeout(() => {
      fetchNFTOwners2();
    }, [1500])
  }, [])

  useEffect(() => {
      if(listOfNft.length >= 0){
        setPaginationLength(listOfNft.length)
      }
  }, [provider, listOfNft])

  const fetchNFTOwners2 = async () => {
    const options = {
      address: addressSearched,
      chain: Moralis.Chains.POLYGON_MAINNET,
    };
    const NFTS = await Web3Api.account.getNFTs(options)

    console.log(NFTS)
    setNfts(NFTS)

    setListOfNft(NFTS.result)

    setInitialLoading(false)
  };


  const goToSearch = () => {
    navigate("/view-friend-dtc")
  }


  //sacar el "!"
  if (initialLoading) {
    return <GridLoader color='#E9E9E9' style={{position: 'fixed',margin: 'auto', inset: '45%'}}/>;
  } else {
    return (
      <>
        <div className='height-all-less-footer'>
          <Navigation />
          <GoBack value="View Friend's DTC" link='/view-friend-dtc'/>
          <Container className="mis-nft">
            <Row className='mt-3 mb-2'>
              <Col className='m-0'>
                  <div style={{fontWeight: '750'}}>DTCs of:</div>
                  <div>{addressSearched.slice(0,6)}...{addressSearched.slice(36,42)}</div>
              </Col>
              <Col className='m-0'>
              <Button onClick={()=>{goToSearch()}} type="submit" className="max-width-form w-100 send-button" style={{fontSize: '1rem', margin: '0px', padding: '0px'}}>New Search</Button>
              </Col>
            </Row>
          <Row className="row-cards">
            {listOfNft.length > 0 ?  _DATA.currentData().map((nft, index) => (
                  (nft.metadata === null && nft.token_uri !== null) ?
                    <>
                    <Col xs={6} sm={6} md={6} key={nft.token_hash}>
                      <CardNFT id={nft.token_hash} creator='creator' name={"We don't have the content for this NFT yet. Check back later."} img={"cargando"} address={nft.token_address} />
                    </Col>
                  </>
                  :
                    (nft.metadata === null && nft.token_uri === null) ?
                      <>
                      <Col xs={6} sm={6} md={6} key={nft.token_hash}>
                        <CardLoadMetadata _tokenId={nft.token_id} _tokenAddress={nft.token_address}/>
                      </Col>
                      </>
                      :
                        <>
                          <Col xs={6} sm={6} md={6} key={nft.token_hash}>
                            {/*<CardNFT id={nft.token_hash} creator='creator' name={JSON.parse(nft?.metadata)?.name} img={(((JSON.parse(nft?.metadata)?.image)?.slice(0,7)) === "ipfs://") ? "https://ipfs.io/ipfs/" + ((JSON.parse(nft?.metadata)?.image)?.slice(7)) :  (((JSON.parse(nft?.metadata)?.image)?.slice(0,12)) === "ipfs://ipfs/") ? "https://ipfs.io/" + ((JSON.parse(nft?.metadata)?.image)?.slice(12)) : JSON.parse(nft?.metadata)?.image} link={url === 'my-dtcs' ? `/my-dtcs/${nft.token_hash}` : `/my-dtcs/send/${nft.token_hash}`} />*/}
                            <CardNFT id={nft.token_hash} creator='creator' name={JSON.parse(nft?.metadata)?.name} img={(((JSON.parse(nft?.metadata)?.image)?.slice(0,12)) === "ipfs://ipfs/") ? "https://ipfs.io/ipfs/" + ((JSON.parse(nft?.metadata)?.image)?.slice(12)) :  (((JSON.parse(nft?.metadata)?.image)?.slice(0,7)) === "ipfs://") ? "https://ipfs.io/ipfs/" + ((JSON.parse(nft?.metadata)?.image)?.slice(7)) : JSON.parse(nft?.metadata)?.image} link={`/landingpagev2/${nft.owner_of}/${nft.token_address}/${nft.token_id}`} />
                          </Col>
                        </>
              ))
              
              : 
              <>
                <div style={{minHeight: '50vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly'}}>
                  <img src={NFTBigger} alt='nft' />
                  <p style={{fontSize: '21px'}}>STILL NO DTCS HERE</p>
                </div>
              </>
              }  
            </Row>
            <Row>
              {listOfNft.length === 0 ? null : <Pagination count={Math.max(Math.ceil(paginationLength / limit), 1)} page={page} onChange={handlePaginationChange} color="primary" />}
            </Row>
           
          </Container>
        </div>
        <FooterOpciones />
      </>
    );
  }
};

export default NftAmigoAddress;
