import * as React from 'react';
import { Row } from 'react-bootstrap';
import SessionCard from './SessionCard';

const WalletsConnected = ({ _sessions, _version = 2 }) => {

    if (!_sessions) return <Row>No _sessions yet</Row>

    if (_version === 2) return (
        <>
            {_sessions && _sessions.map(session => {
                const { name, icons, url } = session.peer.metadata;

                return session.namespaces.eip155.accounts.map((account) =>
                    <SessionCard
                        key={account}
                        topic={session.topic}
                        icon={icons[0]}
                        name={name}
                        url={url}
                        address={account.substring(account.lastIndexOf(':') + 1)}
                        chain={account.substring(account.indexOf(':') + 1, account.lastIndexOf(':'))}
                    />
                )
            }
            )}
        </>
    )

    if (_version === 1) return (
        <>
            {_sessions && _sessions.map(session => {
                const { name, icons, url } = session.peerMeta;

                return session.accounts.map((account) =>
                    <SessionCard
                        key={account}
                        topic={session.topic}
                        icon={icons[0]}
                        name={name}
                        url={url}
                        address={account.substring(account.lastIndexOf(':') + 1)}
                        chain={session.chainId.toString()}
                    />
                )
            }
            )}
        </>
    )
}

export default WalletsConnected