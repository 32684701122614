import * as React from 'react';
import { Button, Row } from 'react-bootstrap';
import { QrReader } from 'react-qr-reader';
import { useWalletConnectClient } from '../../context/walletConnectContext';

const ConnectScanner = () => {

    const { signClient, pair, createClient, legacy_pair } = useWalletConnectClient();

    const [openQr, setOpenQr] = React.useState(false);

    const connect = async (result, error) => {
        try {
            if (!!result) {
                const uri = result?.text;
                /* version 1 */
                if (uri.includes('@1?bridge')) await legacy_pair(uri)
                /* version 2 */
                else if (signClient) await pair(uri);
                else if (!signClient) await createClient()
            }

            if (!!error) return
        } catch (err) {
            console.log(err)
        }
    };



    return (
        <Row className="justify-content-center my-4">
            {!openQr && <>
                <label style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "400px",
                    height: "400px",
                    border: "2px dashed rgba(175, 175, 175, 0.94)",
                    borderRadius: "5%",
                    cursor: "pointer",
                    marginTop: "1%"
                }}
                >
                    <img src={'https://react-wallet.walletconnect.com/icons/qr-icon.svg'} alt='qr code scan' width={125} className="my-3" />
                    <Button className="px-5" onClick={() => setOpenQr(prevState => !prevState)}>Scan QR Code</Button>
                </label>

            </>}

            {openQr
                ? <QrReader
                    constraints={{ facingMode: 'environment' }}
                    onResult={(result, error) => {if(result){connect(result, error)}}}
                    style={{ width: '100%' }}
                />
                : null
            }

        </Row >
    );
}

export default ConnectScanner