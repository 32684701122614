import React, { useEffect, useContext, useState } from "react";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import LogoDTC from "../../assets/dtc-logo-21-blue1400.jpg";
import { Container, Col, Row, Stack } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import GridLoader from "react-spinners/GridLoader";
import './ClaimNft.css';
//context
import { UserContext } from "../../context/userContext";
import Moralis from 'moralis';

const ClaimNft = () => {
  const { connectClaim, currentAccount, setCode, code, setTemplateId, setParams, params } = useContext(UserContext);

  let { hash } = useParams();
  let navigate = useNavigate();
  const ClaimNft = "You are logged in with: " + currentAccount;
  const ClaimOtraCuenta = "This DTC was claimed by other account";
  const ClaimSuccessfull = "Your claim was successfull!";
  const [info, setInfo] = useState();
  const [reclamado, setReclamado] = useState();
  const [reclamadoPor, setReclamadoPor] = useState();
  const [searchParams] = useSearchParams();
  const [initialLoading, setInitialLoading] = useState(true);
  const [secondLoading, setSecondLoading] = useState(true);


  const goMisNfts = () => {
    navigate('/my-dtcs');
  }

  const getCodeInfo = async () => {
    const _info = await Moralis.Cloud.run("GetCodeInfo", { id: params.id });
    console.log(_info)
    setInfo(_info);
    setReclamado(_info.attributes.claimed)
    if(_info.attributes.claimed){
      setReclamadoPor(_info.attributes.to)
    }
  }

  useEffect(() => {
    getCodeInfo();
    setCode(hash);
    const params = {}
    for (const [key, value] of searchParams.entries()) {
      params[key] = value
    }
    setParams(params)
    if (searchParams.get('templateId')) {
      setTemplateId(searchParams.get('templateId'));
    }
  }, [hash, code]);

  useEffect(() => {
    if((info !== undefined)){
      setSecondLoading(false)
    }
    console.log("info", initialLoading)
    console.log("info", secondLoading)
  }, [info]);

  React.useEffect(() => {
    setTimeout(() => {
      console.log(currentAccount)
      setInitialLoading(false)
    }, [2000])
  }, [currentAccount]);

  
   if(initialLoading){
    return <GridLoader color='#E9E9E9' style={{ position: 'fixed', margin: 'auto', inset: '45%' }} />;
  }else{
    /*if(secondLoading){
      return <GridLoader color='#E9E9E9' style={{ position: 'fixed', margin: 'auto', inset: '45%' }} />;
    }else{*/
      return(
        <>{(currentAccount === "") ?
          <>
            <div style={{backgroundColor: '#002E74', minHeight: '100vh'}}>
              <div className="centrar-todo">
                <Container>
                  <Stack
                    className="d-flex flex-column align-items-center justify-content-evenly tamaño-imagenes"
                    style={{ marginBottom: "1rem", alignItems: "center" }}>
                      <img src={LogoDTC} alt="Dapps Factory" style={{width: '500px'}}/>
                  </Stack>
                  <Stack className="form-width">
                    <Button onClick={connectClaim} className='btn-claim'>Login to Claim</Button>
                  </Stack>
                  <Stack>
                    <div style={{ textAlign: 'center', marginTop: '1rem' }}>{(reclamado) ? <>{"This DTC was alredy claimed by an account ended in '0x..." + reclamadoPor?.slice(39, 42) + "'"}</> : <></>}</div>
                  </Stack>
                </Container>
              </div>
            </div>  
          </>
          :
          <>
            <div style={{backgroundColor: '#002E74', minHeight: '100vh'}}>
              <h1 style={{ textAlign: 'center', fontSize: '1rem', marginTop: '0.5rem' }}>{ClaimNft}</h1>
                <div className="centrar-todo">
                  <Container>
                    <Stack
                      className="d-flex flex-column align-items-center justify-content-evenly tamaño-imagenes"
                      style={{ marginBottom: "1rem", alignItems: "center" }}>
                        <img src={LogoDTC} alt="Dapps Factory" style={{width: '90%'}}/>
                    </Stack>
                    <Stack className="form-width">
                      <Button className="my-3 btn-claim" onClick={goMisNfts}>Go to my DTCs</Button>
                    </Stack>
                    <Stack>
                      <div style={{ textAlign: 'center', marginTop: '1rem' }}>{(currentAccount === reclamadoPor) ? <>{ClaimSuccessfull}</> : <>{ClaimOtraCuenta + " (ended in '0x..." + reclamadoPor?.slice(39, 42) + "')"}</>}</div>
                    </Stack>
                  </Container>
                </div>
            </div>
          </>
        }
        </>
      )
    //}
  } 

//   if(initialLoading){
//     return <GridLoader color='#E9E9E9' style={{ position: 'fixed', margin: 'auto', inset: '45%' }} />;
//   }else{
//   if (!currentAccount) {
//     return (<div style={{backgroundColor: '#002E74', minHeight: '100vh'}}>
//       {/*<h1 style={{textAlign: 'center', fontSize: '1rem', marginTop: '0.5rem'}}>{ClaimNft}</h1>*/}
//       <div className="centrar-todo">
//         <Container>
//           <Stack
//             className="d-flex flex-column align-items-center justify-content-evenly tamaño-imagenes"
//             style={{ marginBottom: "1rem", alignItems: "center" }}>
//             {/*           <img src={LogoDapps} alt="Dapps Factory" width="256px" />
//    */}          <img src={LogoDTC} alt="Dapps Factory" style={{width: '500px'}}/>
//           </Stack>
//           <Stack className="form-width">
//             <Button onClick={connectClaim} className='btn-claim'>Login to Claim</Button>
//           </Stack>
//           <Stack>
//             <div style={{ textAlign: 'center', marginTop: '1rem' }}>{(reclamado) ? <>{"This DTC was alredy claimed by an account ended in '0x..." + reclamadoPor?.slice(39, 42) + "'"}</> : <></>}</div>
//           </Stack>
//         </Container>
//       </div>
//     </div>
//     );
//   } else {
//     return (<div style={{backgroundColor: '#002E74', minHeight: '100vh'}}>
//       <h1 style={{ textAlign: 'center', fontSize: '1rem', marginTop: '0.5rem' }}>{ClaimNft}</h1>
//       <div className="centrar-todo">
//         <Container>
//           <Stack
//             className="d-flex flex-column align-items-center justify-content-evenly tamaño-imagenes"
//             style={{ marginBottom: "1rem", alignItems: "center" }}>
//             {/*           <img src={LogoDapps} alt="Dapps Factory" width="256px" />
//    */}          <img src={LogoDTC} alt="Dapps Factory" style={{width: '90%'}}/>
//           </Stack>
//           <Stack className="form-width">
//             <Button className="my-3 btn-claim" onClick={goMisNfts} >Go to my DTCs</Button>
//           </Stack>
//           <Stack>
//             <div style={{ textAlign: 'center', marginTop: '1rem' }}>{(currentAccount === reclamadoPor) ? <>{ClaimSuccessfull}</> : <>{ClaimOtraCuenta + " (ended in '0x..." + reclamadoPor?.slice(39, 42) + "')"}</>}</div>
//           </Stack>
//         </Container>
//       </div>
//     </div>
//     );
//   };
// }
}


export default ClaimNft;