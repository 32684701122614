import React from 'react'
import ShareIcon from '../../assets/btnHome/CompartirFondoAzul.png'


const PwaShare = ({_address}) => {
    const handleShare = () =>{
        if (navigator.share) {
          
            navigator.share({
              title: 'Digital Trading Cards Wallet',
              text: 'This is my DTC address.',
              url: `https://walletdtc.com/address/${_address}`,
              
            })
              .then(() => console.log('Successful share'))
              .catch((error) => console.log('Error sharing', error));
          }
    }


  return (
    <>
        <img src={ShareIcon} alt='share' onClick={handleShare} />
    </>
  )
}

export default PwaShare