import React, { useContext } from 'react'
import { useForm } from "react-hook-form";
import { UserContext } from '../../context/userContext';

const ImageUploader = () => {
    const { register } = useForm()
    const { setImage, setShowImage } = useContext(UserContext);



    const handleChange = (image) => {
        const data = image.target.files[0];
        getBase64(data)
    }
    
    const getBase64 = file => {
        let reader = new FileReader();
        if (file) reader.readAsDataURL(file)
        reader.onload = () => {
            onLoad(reader.result);
        };
    };
    
    const onLoad = fileString => {
        setImage(fileString)
        setShowImage(true);
    };

    return (
        <>
            <input type="file" id='file-upload' onInput={handleChange} style={{display: 'none'}}{...register('picture')} />
            
            <label htmlFor='file-upload' className='upload' style={{color: 'black'}}>ALBUM</label>
            {/* <label>ALBUM</label> */}
        </>
    )
}

export default ImageUploader