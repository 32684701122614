import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
// Stepper
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import {  Typography, Box } from '@mui/material';
import { UserContext } from '../../context/userContext'
import { useContext, useEffect } from 'react';
import BeatLoader from "react-spinners/BeatLoader";
import Moralis from 'moralis';
import './sendButton.css'








const SendButton = ({ _amount, _from, _to, _emailTo, _tokenId, _contractAddress, _function, _nft, _currentUser }) => {

    const {transferFunds,  approveRelayer,  setRefreshInput } = useContext(UserContext)

    const isStepFailed = (step) => {
      return failed === step;
      };

      const steps = [
        {
            label: 'Confirm email address to transfer',
            //description: `Approving collection.`,
            description: `${_emailTo}`,
        },
        {
            label: 'Transfer Fee',
            description:
                'Fees need to be sent to cover the DTC transfer.',
        },
        {
            label: 'Sending email',
            description: `The email has been sent to the recipient.`,
        },
    ];

    // Logica del modal
    const [show, setShow] = React.useState(false);
    const [failed, setFailed] = React.useState('');

    const handleClose = () => {
        setRefreshInput(true)
        setShow(false)
    };

    const handleShow = () => {
        handleReset();  // Vuelve al paso 1
        setShow(true);
    };
    // Logica de los pasos
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = (_step) => {
        if (_step === failed) {
          navigate("/my-dtcs")
        }
        else if (_step === 0) {
            approve(_nft);
        } else if (_step === 1) {
            transfer(_tokenId, _contractAddress)
        } else if (_step === 2) {
            navigate("/my-dtcs")
        }
    };
    const handleReset = () => {
        setActiveStep(0);
        setWaiting(false)
    };

    useEffect(()=> {
        handleShow()
        //handleNext(activeStep)
    }, [])

    // Poner en venta logica
    const [waiting, setWaiting] = React.useState(false);
    let navigate = useNavigate();

    const approve = async (_nft) => {
        try {
            setWaiting(true);
            await approveRelayer(_nft);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setWaiting(false);
        } catch (error) {
            setFailed(0);
            setWaiting(false);
            console.log(error);
         }
    };

    const transfer = async () => {
        try {
            setWaiting(true);
            const tx = await transferFunds(_nft);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            email()
            setWaiting(false);
        } catch (error) {
            setFailed(1);
            setWaiting(false);
            console.log(error);
        }
    };

    const email = async () => {
        try {
            const setNftInfo = await Moralis.Cloud.run('SetNft', {contractAddress: _nft.token_address, tokenId: _nft.token_id, amount: _amount, contractType: _nft.contract_type, owner: _currentUser})
            const url = "https://walletdtc/claim-nft?id=" + JSON.parse(setNftInfo.id).objectId
            const result = await Moralis.Cloud.run("sendEmailToUser", {to: _emailTo, url: url, id: JSON.parse(setNftInfo.id).objectId})
            console.log(result)
        } catch (error) {
            setFailed(2);
            setWaiting(false);
            console.log(error);
        }
    }

   
    

    return (
        <>
        <div style={{width: '100%'}}>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered className='modal-sendButton'>
                <Modal.Header closeButton>
                    <Modal.Title style={{color: 'black'}}>Transfer DTC</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => {
                            const labelProps = {};
                            if (isStepFailed(index)) {
                              labelProps.optional = (
                                <Typography variant="caption" color="error">
                                  Something went wrong.
                                </Typography>
                              );
                              labelProps.error = true;
                            }
                            return (
                              <Step key={step.label}>
                                  <StepLabel sx={{color: 'red'}}
                                      {...labelProps}>
                                        {step.label}
                                  </StepLabel>
                                  <StepContent>
                                  <Box sx={{ mb: 1 }}>
                                      <Typography sx={{color: 'black'}}>{step.description}</Typography>
                                      <div className='mt-2 d-flex align-items-center'>
                                          <label color='black'>{activeStep}</label>
                                          <Button variant="primary" disabled={waiting} className='steps-button' onClick={() => handleNext(index)} sx={{ mt: 1, mr: 1 }}>
                                                  {waiting ? <BeatLoader color='white' size={5} style={{ fontSize: '1rem'}}/> : (index === steps.length - 1) || (index === failed) ? 'Close' : 'Continue'}
                                          </Button>
                                      </div>      
                                  </Box>
                                  </StepContent>
                              </Step>
                            )
                        })}
                    </Stepper>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div>
        </>
    )
}

export default SendButton