import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useWalletConnectClient } from '../../context/walletConnectContext';

const SessionCard = ({ topic = undefined, icon, name, url, address, chain }) => {

    const { disconnect_session } = useWalletConnectClient();

    let chainName;

    switch (chain) {
        case '1':
            chainName = 'Ethereum Mainnet'
            break;
        case '137':
            chainName = 'Polygon Mainnet'
            break
        default:
            break;
    }

    return (
        <>
            <Row className="align-items-center p-2">
                <Col xs={2} className="align-self-start pt-1"><img src={icon} width="45px" height="45px" alt="Connect" /></Col>
                <Col xs={8} className="d-flex flex-column align-items-start">
                    <Row>{name}</Row>
                    <Row>{url}</Row>
                    <Row>{chainName}</Row>
                    <Row>{address.slice(0, 9)}...{address.slice(33)}</Row>
                </Col>
                <Col xs={2} className="align-self-start pt-4" onClick={() => disconnect_session(topic)}> <i className="fa fa-trash" aria-hidden="true" /> </Col>
            </Row>
        </>
    )
}

export default SessionCard