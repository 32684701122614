import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import GridLoader from "react-spinners/GridLoader";
import Tooltip from "@mui/material/Tooltip";
import "./Home.css";
import FooterOpciones from "../footer-completo/footer-opciones";
import Navigation from "../navigation/Navigation";
import { Link } from "react-router-dom";
import MyDTCbtn from "../../assets/btnHome/my-dtc-btn.svg";
import CreateBtn from "../../assets/btnHome/create-btn.svg";
import TransferBtn from "../../assets/btnHome/transfer-btn.svg";
import ReceiveBtn from "../../assets/btnHome/receive-btn.svg";
import AddBtn from "../../assets/btnHome/addFunds-btn.svg";
import BalanceBtn from "../../assets/btnHome/balance-btn.svg";
import CopyIcon from "../../assets/btnHome/CopiarFondoAzul.png";
import PwaShare from "../../utils/pwa-share/pwa-share";
//context
import { UserContext } from "../../context/userContext";
import BalanceHeader from "../balance-header/BalanceHeader";
import Image from "react-bootstrap/Image";
import TwoPhones from "../../assets/landing/imagenBajaDTC.png";

const Home = () => {
  const { currentAccount, nfts, balanceInMatic, fetchNFTOwners, loader } =
    useContext(UserContext);
  const [initialLoading, setInitialLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const { register } = useForm();

  const { handleChange } = useContext(UserContext);

  const url =
    "https://widget.onramper.com?color=13054c&apiKey=pk_prod_D_RPmbPWAgiHdh_2kK60kFkjcTugHAMKkao3qk2Z0Ko0&defaultCrypto=MATIC&supportSell=false&supportSwap=false&defaultFiat=USD&defaultAmount=32&onlyGateways=Moonpay&wallets=MATIC:" +
    currentAccount +
    "&onlyCryptos=MATIC&onlyFiat=EUR,USD,BRL,GBP,CAD&isAddressEditable=false";

  function handleClick() {
    setOpen(true);
    navigator.clipboard.writeText(currentAccount);
    setTimeout(() => {
      setOpen(false);
    }, 600);
  }

  useEffect(() => {
    setTimeout(() => {
      fetchNFTOwners();
      setInitialLoading(false);
    }, [1500]);
  }, []);

  if (initialLoading) {
    return (
      <GridLoader
        color="#E9E9E9"
        style={{ position: "fixed", margin: "auto", inset: "45%" }}
      />
    );
  } else {
    return (
      <>
        <div className='height-all-less-footer' id="Home">
          <Navigation pageWrapId={"page-wrap"} outerContainerId={"Home"} />
          <BalanceHeader />
          <Container
            className="d-xl-none d-lg-none d-md-none d-sm-block"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              backgroundColor: "#002F7C",
            }}
          >
            <Row>
              <Col>
                <label className="address-text">My Address</label>
              </Col>
            </Row>
            <Row className="AdressAndLogos ">
              <Col>
                <label onClick={handleClick}>
                  {currentAccount.slice(0, 7)}...{currentAccount.slice(35)}
                </label>
                <Tooltip
                  title="Copied"
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                >
                  <img
                    src={CopyIcon}
                    alt="copy"
                    onClick={handleClick}
                    style={{ marginLeft: "1rem", marginRight: "1rem" }}
                  />
                </Tooltip>
                <PwaShare _address={currentAccount} />
              </Col>
            </Row>
          </Container>

          {loader ? (
            <GridLoader
              color="#E9E9E9"
              style={{ position: "fixed", margin: "auto", inset: "45%" }}
            />
          ) : (
              <Container
                className="d-flex flex-column align-items-center justify-content-evenly div-icons"
                style={{ minHeight: "calc(70vh - 66px)" }}
              >
                <Row className="img-box-row">
                  <Col
                    md="5"
                    className="d-xl-block d-lg-block d-md-block d-none"
                  >
                    <Image
                      className="img-fluid"
                      src={TwoPhones}
                      alt="two phones"
                    />
                  </Col>
                  <Col md="7" className="d-flex-middle">
                    <Row>
                      <Col md="4" xs="6">
                        <Link to="/my-dtcs">
                          <img src={MyDTCbtn} alt="back-images" />
                        </Link>
                      </Col>
                      <Col md="4" xs="6">
                        <label htmlFor="file-upload">
                          <img src={CreateBtn} alt="back-images" />
                          <input
                            type="file"
                            id="file-upload"
                            onInput={handleChange}
                            style={{ display: "none" }}
                            {...register("picture")}
                          />
                        </label>
                      </Col>

                      <Col md="4" xs="6">
                        <Link to="/send">
                          <img src={TransferBtn} alt="back-images" />
                        </Link>
                      </Col>
                      <Col md="4" xs="6">
                        <Link to="/receive">
                          <img src={ReceiveBtn} alt="back-images" />
                        </Link>
                      </Col>

                      <Col md="4" xs="6">
                        <a href={url} target="_blank" rel="noreferrer">
                          <img src={AddBtn} alt="back-images" />
                        </a>
                      </Col>
                      <Col md="4" xs="6">
                        <Link to="/balance">
                          <img src={BalanceBtn} alt="back-images" />
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            
          )}
        </div>
        <FooterOpciones />
      </>
    );
  }
};

export default Home;
