import React, { useContext, useEffect } from "react";
import "./Send.css";
import { Container, Stack, Form, Button, Row, Col } from "react-bootstrap";
import Navigation from "../navigation/Navigation";
import GoBack from "../goBack/GoBack";
import { useState } from "react";
import { UserContext } from "../../context/userContext";
import InputGroup from 'react-bootstrap/InputGroup';
import Scanner from "../scanner/scanner";
import RecentDestinations from "../recent-destinations/recent-destinations";
import FooterOpciones from "../footer-completo/footer-opciones";
import Loader from '../../utils/loader/Loader'
import axios from 'axios';
import { ethers } from 'ethers';

import Modales from "../../utils/modal/modal";

const Send = () => {
  const [receiver, setReceiver] = useState("");
  const [coin, setCoin] = useState("MATIC");
  const [transferAmount, setTransferAmount] = useState("");
  //const [childData, setChildData] = useState("");
  const [modalIsShown, setModalIsShown] = useState(false);
  const { currentAccount, loader, openConfirmationModal, balance, getMaxTokensAndGas, balanceInMatic, transferError } = useContext(UserContext);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [hideQr, setHideQr] = useState(true)
  const [isValid, setIsValid] = useState(false);


  const camaraToAddContact = (dataReceiveFromParent) => {
    setReceiver(dataReceiveFromParent);
    document.getElementById('receiver').value = dataReceiveFromParent;
  };

  const modalToParent = () => {
    setModalIsShown(false);
  };

  const handleSubmit = (event) => {   
    const form = event.currentTarget;
    
      setModalIsShown(true);
    
    event.preventDefault();
    event.stopPropagation();
    setFormSubmitted(true);
  };

 

  useEffect(() => {
    if(receiver !== ''){
      setHideQr(true)
    }
  }, [receiver])

  const maticBalanceNoGas = async () => {
    const maxTokensAndGas = await getMaxTokensAndGas()
    setTransferAmount(maxTokensAndGas.toString())
  } 

  const validate = () => {
    return receiver.length && transferAmount > 0.0 && transferAmount < balanceInMatic;
  }

  useEffect(() => {
    const isValid = validate();
    setIsValid(isValid);
  }, [receiver, transferAmount]);

  return (
    <>
    

      <div className='height-all-less-footer'>
        <Navigation />
        <GoBack value="Send" link='/send'/>
        <RecentDestinations setReceiver={setReceiver} param="send" />
        <Container>
          <Stack style={{ textAlign: "center" }} gap={2}>
            <h3>
              {!hideQr ? <> Scan QR </> : <> Send Matic </>} 
            </h3>
            {!hideQr ? <Scanner scannerToParent={camaraToAddContact} /> : null}
          </Stack>
          <Form className="max-width-form form-send" noValidate id="SendForm" autoComplete="off" onSubmit={handleSubmit}>
            <Form.Group className="mb-2 mt-4">
              <Row>
                <Col className="mb-2 max-button-col">
                  <InputGroup className="mb-3 mt-2" hasValidation>
                    
                    <Form.Control
                      required
                      type="text"
                      placeholder="Address"
                      // defaultValue={childData ? childData : addressCopy}
                      value={receiver}
                      name="receiver"
                      id="receiver"
                      onChange={(e) => {
                        setReceiver(e.target.value);
                      
                      }}
                      isInvalid={!ethers.utils.isAddress(receiver) && (receiver.length || formSubmitted)}
                      isValid={ethers.utils.isAddress(receiver)}
                    />
                    
                      <InputGroup.Text id="basic-addon1" onClick={()=>setHideQr(!hideQr)}><i className="fa-solid fa-camera"></i></InputGroup.Text>
                    
                    <Form.Control.Feedback type="invalid">Invalid Address</Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col className="mb-2 max-button-col">
                  <InputGroup className="mb-3" hasValidation>
                    
                    <Form.Control
                      type="number"
                      placeholder="Amount"
                      defaultValue=""
                      value={transferAmount}
                      min="0"
                      max="9999"
                      name="transferAmount"
                      id="transferAmount"
                      onChange={(e) => {
                        setTransferAmount(e.target.value);
                        
                      }}
                      style={{zIndex: '0'}}
                      required
                      isInvalid={
                        (transferAmount.length === 0 &&
                          (transferAmount.length || formSubmitted)) ||
                          parseFloat(transferAmount)  > balanceInMatic ||
                        transferAmount === 0
                      }
                      isValid={
                        parseFloat(transferAmount) > 0 && parseFloat(transferAmount) < balanceInMatic
                      }                    />
                    {/* <Button className="max-button" onClick={() => maticBalanceNoGas()} style={{zIndex: '0'}}> Max </Button> */}
                    
                    <Form.Control.Feedback type="invalid">Invalid Amount
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </InputGroup>
                </Col>
                <Col className="mb-2" style={{display: 'flex', alignItems: "baseline", justifyContent: "space-evenly"}}>
                  
                    <label style={{color: 'white'}}>{balanceInMatic.slice(0,6)}</label>               
                    <label style={{color: 'white', fontWeight: 'bold'}}>MATIC</label>
                 
                </Col>
              </Row>
              <Button type="submit" disabled={!isValid} className="max-width-form w-100 send-nft">Send</Button>
              {(modalIsShown) &&
                <Modales modalToParent={modalToParent}
                  _from={currentAccount}
                  _to={receiver}
                  _amount={transferAmount}
                  _coin={coin}
                  _function={"Transfer"}
                  _icon={"no"}
                  _title={"Please Confirm Transaction"}
                ></Modales>
              }
              {(openConfirmationModal) &&
                <Modales 
                  modalToParent={modalToParent}
                  action={"Transfer Done"}
                  _from={currentAccount}
                  _icon={"check"}
                  _title={"Transaction Success"}
                  _text={"Your Matics were transfered succesfully."}
                ></Modales>
              }
              {(loader) &&
                <Modales modalToParent={modalToParent}
                  _function={"processing"}
                  _title={" "}
                  // _icon={"Pregunta"}
                  _icon={"no"}
                  _name={"Processing transaction"}
                  _description={"Please do not close this site"}
                ></Modales>
              }

              {(transferError) &&
                <Modales 
                  modalToParent={modalToParent}
                  _icon='cancel'
                  _title={"Transaction cancelled"}
                  /* _text={"There was an error sending your Matic, please check if you have enought balance for this transaction."} */
                ></Modales>
              }
            </Form.Group>
          </Form>
          <Stack>
          </Stack>
        </Container>
      </div>
      <FooterOpciones />
    </>
  );
};

export default Send;