import React, {useEffect, useState} from 'react'
import Modal from 'react-modal';
import './ImageModal.css'

const ImageModal = ({_image}) => {

    const [isOpen, setIsOpen] = useState(true)

    const handleOpenModal = () =>{
        setIsOpen(true)
    }

    const handleCloseModal = () =>{
      setIsOpen(false)
  }

  return (
    <div >
        {/*<button onClick={handleOpenModal}>See FullScreen</button>*/}
        <Modal 
           isOpen={isOpen}
           contentLabel="onRequestClose Example"
           onRequestClose={handleCloseModal}
           shouldCloseOnOverlayClick={true}
           //style={{paddingTop: '5rem'}}
           className='modal-img'
        >
          
          <img src={_image} className='nftImage-modal' alt='nftImage' /*style={{width:'100%', maxHeight:'80vh'}}*/ />
          {/* <button onClick={handleCloseModal}>Close FullScreen</button> */}
        </Modal>
      </div>
  )
}

export default ImageModal;