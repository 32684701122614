import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";
import "./App.css";
import Moralis from "moralis";
import Home from "./components/home/Home";
import Agenda from "./components/agenda/Agenda";
import AddContact from "./components/add-contact/Add-contact";
import AddFunds from "./components/add-funds/Add-funds";
import Login from "./components/login/Login";
import Receive from "./components/receive/Receive";
import Send from "./components/send/Send";
import SendSelect from "./components/SendSelect/SendSelect"
import MisNFT from "./components/MisNFT/MisNFT";
import Balance from "./components/Balance/Balance";
import CameraNFT from "./utils/camera/CameraNFT";
import MakeNFT from "./utils/MakeNft/MakeNFT";
import RequireConnected from "./pages/RequireConnected";
import NftDetails from "./components/nftDetails/NftDetails";
import CameraGallery from "./utils/MakeNft/CameraGallery";
import ImageUploader from "./utils/camera/imageUploader";
import LandingPage from "./components/landing-page/LandingPage";
import ClaimNft from "./components/claim-nft/ClaimNft"
import FullScreenScanner from "./components/fullscreenScanner/FullScreenScanner";
import Address from "./components/address/address";
import Test from './utils/test/test';
import UploadCsv from "./utils/UploadCsv/UploadCsv";
import Profile from "./components/profile/Profile";
import Landing from "./pages/landing/Landing";
import Terms from "./pages/Terms/Terms";
import Privacy from "./pages/Terms/Privacy";
import FirstTime from "./pages/firstTime/FirstTime";
import LandingPageV2 from "./components/landing-page/LandingPageV2";
import NftAmigoLoader from "./components/nft-amigo/NftAmigoLoader";
import NftAmigoAddress from "./components/nft-amigo/NftAmigoAddress";
import ConnectWallet from "./pages/ConnectWallet";


// eslint-disable-next-line no-undef 

function App() {
  useEffect(() => {
    Moralis.start({
      appId: process.env.REACT_APP_MORALIS_ID,
      serverUrl: process.env.REACT_APP_MORALIS_URL,
   });
  }, []);
  const location = useLocation();

  return (
    <>
      <Routes>
        <Route path="/" exact element={<Outlet />} />
        <Route path="/test" exact element={<Test />} />
        {/* Solo si estas loggeado asd*/}
        <Route element={<RequireConnected />}>
          <Route index element={<Navigate to="/home" state={{ from: location }} replace />} />
          <Route path="/home" exact element={<Home />} />
          <Route path="/receive" exact element={<Receive />} />
          <Route path="/send" exact element={<SendSelect />} />
          <Route path="/send-token" exact element={<Send />} />
          <Route path="/my-dtcs" exact element={<MisNFT />} />
          <Route path="/my-dtcs/send" exact element={<MisNFT />} />
          <Route path="/my-dtcs/:index" exact element={<NftDetails />} />
          <Route path="/my-dtcs/send/:index" exact element={<NftDetails />} />
          <Route path="/scanner/:index" exact element={<FullScreenScanner />} />
          <Route path="/balance" exact element={<Balance />} />
          <Route path="/make-dtc" exact element={<CameraGallery />} />
          <Route path="/make-dtc/camera" exact element={<CameraNFT />} />
          <Route path="/make-dtc/gallery" exact element={<ImageUploader />} />
          <Route path="/make-dtc/make" exact element={<MakeNFT />} />
          <Route path="/agenda" exact element={<Agenda />} />
          <Route path="/add-contact" exact element={<AddContact />} />
		      <Route path="/add-funds" exact element={<AddFunds />} />
          <Route path="/profile" exact element={<Profile />} />
          <Route path="/admin" exact element={<UploadCsv />}/>
          <Route path="/connect-wallet" exact element={<ConnectWallet />} />
          <Route path="/view-friend-dtc" exact element={<NftAmigoLoader />} />
          <Route path="/view-friend-dtc/:addressSearched" exact element={<NftAmigoAddress />} />
          <Route path="/landingpagev2/:ownerAddress/:tokenAddress/:tokenId" exact element={<LandingPageV2 />} />
		    </Route>
          <Route path="/login" exact element={<Login />} />
          <Route path="/landing" exact element={<Landing />} />
          <Route path="/login/:param" exact element={<Login />} />
          <Route path="/landingpage/:tokenAddress/:tokenId" exact element={<LandingPage />} />
          <Route path="/claim-nft" exact element={<ClaimNft />} />
          <Route path="/address/:hash" exact element={<Address />} />
          <Route path="/privacypolicy" exact element={<Privacy />} />
          <Route path="/termsandconditions" exact element={<Terms />} />
          <Route path="/firstlogin" exact element={<FirstTime />} />
      </Routes>
    </>
  );
}
export default App;
