import React, {  useContext } from "react";
import './Add-funds.css'
import { Container
 } from "react-bootstrap";
import Navigation from '../navigation/Navigation';
import GoBack from '../goBack/GoBack';
import { UserContext } from "../../context/userContext";
import FooterOpciones from "../footer-completo/footer-opciones";

const Receive = () => {


    const { currentAccount } = useContext(UserContext);
    //pk_prod_D_RPmbPWAgiHdh_2kK60kFkjcTugHAMKkao3qk2Z0Ko0
    const url = "https://widget.onramper.com?color=13054c&apiKey=pk_prod_D_RPmbPWAgiHdh_2kK60kFkjcTugHAMKkao3qk2Z0Ko0&defaultCrypto=MATIC&supportSell=false&supportSwap=false&defaultFiat=USD&defaultAmount=32&onlyGateways=Moonpay&wallets=MATIC:"+currentAccount+"&onlyCryptos=MATIC&onlyFiat=EUR,USD,BRL,GBP,CAD&isAddressEditable=false"
    
    //const apiKey = "pk_prod_D_RPmbPWAgiHdh_2kK60kFkjcTugHAMKkao3qk2Z0Ko0"
    
    //const defaultAmount = 32;

    //const apiKeyTest = "pk_test_kHB7hv1FqPIkApbLIYOzSG1A3xAwy_0QPet9D6ntgkY0"
    //const test = "pk_test_x5M_5fdXzn1fxK04seu0JgFjGsu7CH8lOvS9xZWzuSM0"
    



        return (
            <>
                    <Navigation />
                    <GoBack value="Add Funds" link='/home'/>
                    <Container className="add-funds" id="addFunds">
                       
                    <iframe
                        className="iframe-buycripto"
                        src={url}
                        height="100%"
                        width="100%"
                        title="Onramper widget"
                        frameBorder="0"
                        allow="accelerometer;
                        autoplay; camera; gyroscope; payment"
                        >
                        <a href="https://widget.onramper.com" target="_blank" rel='noreferrer'>Buy crypto</a>
                    </iframe>
                     
                    </Container>
                <FooterOpciones /> 
            </>
        )
    
}

export default Receive;
