import React, { useContext, useEffect, useState } from "react";
import "./Balance.css";
import { Row, Col, Stack, Container } from "react-bootstrap";

import Navigation from "../navigation/Navigation";
import GoBack from "../goBack/GoBack";
import { UserContext } from "../../context/userContext";
import FooterOpciones from "../footer-completo/footer-opciones";
import DTCMini from '../../assets/DTC-mini.png';
import MaticMini from '../../assets/matic-mini.png';

import GridLoader from "react-spinners/GridLoader";
import BalanceHeader from "../balance-header/BalanceHeader";


const Balance = () => {
  const { balanceInMatic, transactions, currentAccount, nfts } = useContext(UserContext);
  const [loadingState, setLoadingState] = useState(true)

  const defender = "0x057163929ce520d7827d5c334432293fc3eec3c5";
  const usuario = currentAccount;
  const contractAddressEncriptado ="0x59b684b31D234230a60F87e4220cc7d5CB9c5642";


  const checkData = (transaction) => {
    switch (transaction.result.method) {
      case 'createCollectionAndMint':
        return <><h6>Minted DTC:</h6><h6>"{(transaction.result.inputs[1])}"</h6><h6 style={{ fontSize: '0.8rem' }}>(DTCs minted: {parseInt(transaction.result.inputs[2])})</h6></>
      case 'safeTransferFrom':
        return <><h6>DTC transfered to:</h6><h6>0x{transaction.result.inputs[1].slice(0, 4) + '...' + transaction.result.inputs[1].slice(34)}</h6><h6 style={{ fontSize: '0.8rem' }}>{transaction.email ? '(' + transaction.email + ')' : ''}</h6><h6 style={{ fontSize: '0.8rem' }}>NFT ID: {parseInt(transaction.result.inputs[2])}</h6></>
      case 'setApprovalForAll':
        return `DTC transfered by email`
      case null:
        return `No info of this transaction`
      default:
        if (transaction.to_address) {
          return `Interacted with ${transaction?.to_address.slice(0, 4) + '...' + transaction?.to_address?.slice(32)}`
        }
    }
  }

  useEffect(() => {
    if (transactions?.result) {

      setLoadingState(false)
    }

  }, [transactions])

  const conversorMatic = 10 ** 18;


    return (
      <>
      <div className='min-height-margin'>
        <Navigation />
        <BalanceHeader />
        <GoBack value="Balance" link='/home' />

        <Stack className="transacciones">
          <div className="container d-xxl-none d-xl-none d-lg-none">
            <h5>Latest Transactions</h5>
            <h5>Matic</h5>
            {/*<i className="fa-solid fa-sliders"></i>*/}
          </div>
          <div className="d-none d-lg-block" style={{textAlign: 'center', margin: '2.5rem  0', width: '100%'}}>
            <h3 className='title-with-gradient-underline'>Balance - Latest transactions</h3>
          </div>
          <div>
             
            {loadingState ? <GridLoader color='#E9E9E9' size={20} style={{position: 'fixed',margin: 'auto', inset: '45%'}} /> : 
             <Container>
             {transactions.result?.map((transaction, index) => (
               <Row className="transaccion" key={index}>
                 <div className="container">
                   {(transaction.from_address === usuario) ?
                     (transaction.input === '0x') ?
                       (transaction.to_address === defender) ?
                         <>
                           <Col md={6} xs={6}>
                             <h6 className="wordbreak">NFT transfered by e-mail</h6>
                             <span style={{fontSize:'0.7rem'}}>{transaction.block_timestamp.slice(0,10)} ({transaction.block_timestamp.slice(11,16)} UTC)</span>
                           </Col>
                           <span style={{color: 'red', textAlign: 'center  ', display: 'flex', alignItems: 'center'}}>{(((((transaction.gas_price) * (transaction.gas)) / conversorMatic) + (transaction.value / conversorMatic)).toString()).slice(0,9)} MATIC</span>
                         </>
                         :
                         <>
                           <Col md={6} xs={6}>
                             <h6 className="wordbreak">Matic transfered to:</h6>  
                             <h6 className="wordbreak">{(transaction.to_address.slice(0,6) + '...' + transaction.to_address.slice(36))}</h6>      
                             <h6 className="wordbreak" style={{fontSize:'0.7rem'}}>{transaction.email ? '(' + transaction.email + ')' : ''}</h6>  
                             <span style={{fontSize:'0.7rem'}}>{transaction.block_timestamp.slice(0,10)} ({transaction.block_timestamp.slice(11,16)} UTC)</span>
                           </Col>
                           <span style={{color: 'red', textAlign: 'center  ', display: 'flex', alignItems: 'center'}}>{(((((transaction.gas_price) * (transaction.gas)) / conversorMatic) + (transaction.value / conversorMatic)).toString()).slice(0,9)} MATIC</span>
                         </>
                       :
                       (transaction.to_address === (contractAddressEncriptado).toLowerCase()) ?
                       <>
                         <Col md={6} xs={6}>
                           <h6 className="wordbreak">Send encripted message</h6>
                           <span style={{fontSize:'0.7rem'}}>{transaction.block_timestamp.slice(0,10)} ({transaction.block_timestamp.slice(11,16)} UTC)</span>
                         </Col>
                         <span style={{color: 'red', textAlign: 'center  ', display: 'flex', alignItems: 'center'}}>{(((((transaction.gas_price) * (transaction.gas)) / conversorMatic) + (transaction.value / conversorMatic)).toString()).slice(0,9)} MATIC</span>  
                       </>
                       :
                         <>
                         <Col md={6} xs={6}>
                           <h6 className="wordbreak">{checkData(transaction)}</h6>
                           <span style={{fontSize:'0.7rem'}}>{transaction.block_timestamp.slice(0,10)} ({transaction.block_timestamp.slice(11,16)} UTC)</span>
                         </Col>
                         <span style={{color: 'red', textAlign: 'center  ', display: 'flex', alignItems: 'center'}}>{(((((transaction.gas_price) * (transaction.gas)) / conversorMatic) + (transaction.value / conversorMatic)).toString()).slice(0,9)} MATIC</span>
                       </>
                    :
                       <>
                         <Col md={6} xs={6}>
                           <h6 className="wordbreak">Matic received from:</h6>  
                           <h6 className="wordbreak">{(transaction.from_address.slice(0,6) + '...' + transaction.from_address.slice(36))}</h6>        
                           <span style={{fontSize:'0.7rem'}}>{transaction.block_timestamp.slice(0,10)} ({transaction.block_timestamp.slice(11,16)} UTC)</span>
                         </Col>
                         <span style={{color: 'green', textAlign: 'center  ', display: 'flex', alignItems: 'center'}}>{(((transaction.value / conversorMatic)).toString()).slice(0,9)} MATIC</span>
                       </>
                   }
                 </div>
               </Row>
             ))}

             </Container>
            }
           
          </div>
        </Stack>
        <FooterOpciones />
     
      </div>
    </>
    );
};

export default Balance;

