import * as React from 'react';
import { useWalletConnectClient } from '../../context/walletConnectContext';
import { Row, Button } from 'react-bootstrap';

const SessionProposal = () => {

    const { accept_session, reject_session, signClient, legacySignClient, accept_legacy_session, reject_legacy_session } = useWalletConnectClient();

    if (legacySignClient?.session) {
        return (
            <>
                <div className="d-flex justify-content-center py-3">
                    <img src={legacySignClient?.session.peerMeta.icons[0]} alt={'DApp icon'} width={'50px'} height={'50px'} />
                </div>
                <Row className="justify-content-center px-5 my-3 text-center"><h3>{legacySignClient?.session.peerMeta.name} wants to connect with your wallet.</h3></Row>
                <Row><p className="my-0">View your wallet balance and activity</p></Row>
                <Row><p className="my-0">Request approval of transactions</p></Row>
                <Row className="justify-content-center my-3"><Button className="px-4" onClick={accept_legacy_session}>Connect</Button></Row>
                <Row className="justify-content-center my-3"><Button className="px-4" onClick={reject_legacy_session}>Reject</Button></Row>
            </>
        )
    }
    return (
        <>
            <div className="d-flex justify-content-center py-3">
                <img src={signClient?.proposal?.values[0]?.proposer?.metadata.icons[0]} alt={'DApp icon'} width={'50px'} height={'50px'} />
            </div>
            <Row className="justify-content-center px-5 my-3 text-center"><h3>{signClient?.proposal?.values[0]?.proposer?.metadata.name} wants to connect with your wallet.</h3></Row>
            <Row><p className="my-0">View your wallet balance and activity</p></Row>
            <Row><p className="my-0">Request approval of transactions</p></Row>
            <Row className="justify-content-center my-3"><Button className="px-4" onClick={accept_session}>Connect</Button></Row>
            <Row className="justify-content-center my-3"><Button className="px-4" onClick={reject_session}>Reject</Button></Row>
        </>
    )
}

export default SessionProposal