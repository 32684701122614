import React from 'react'
import './footer.css'
// import { Container, Row, Col } from 'react-bootstrap'

const Footer = () => {
  return (
    <div className="footer"></div>
  )
}

export default Footer