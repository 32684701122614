import React from 'react'
import { usePWAInstall } from 'react-use-pwa-install'
import "./instalpwa.css";


export const InstallPWA = () => {
    const install = usePWAInstall()

    return (
        <header >
            {install &&
                <button className="add-pwa" onClick={install}>
                    <i className="fa-solid fa-cloud-arrow-down" style={{color: '#002f7c'}}></i>
                    {/* <span>Download App</span> */}
                </button>}
        </header>
    )
}

export default InstallPWA;