import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Stack } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import "./BalanceHeader.css";
import DTCMini from '../../assets/DTC-mini.png';
import MaticMini from '../../assets/matic-mini.png';
import { Link } from "react-router-dom";
//context
import { UserContext } from "../../context/userContext";
import { CompartirAddresshatsapp } from "../../utils/Share-social";
import PwaShare from '../../utils/pwa-share/pwa-share';
import CopyIcon from '../../assets/btnHome/CopiarFondoAzul.png';


const BalanceHeader = () => {
    const { currentAccount, balanceInMatic, fetchNFTOwners, } = useContext(UserContext);
    const [initialLoading, setInitialLoading] = useState(true);
    const [open, setOpen] = useState(false);
  
    const { nfts } =
      useContext(UserContext);
  
    const url =
      "https://widget.onramper.com?color=13054c&apiKey=pk_prod_D_RPmbPWAgiHdh_2kK60kFkjcTugHAMKkao3qk2Z0Ko0&defaultCrypto=MATIC&supportSell=false&supportSwap=false&defaultFiat=USD&defaultAmount=32&onlyGateways=Moonpay&wallets=MATIC:" +
      currentAccount +
      "&onlyCryptos=MATIC&onlyFiat=EUR,USD,BRL,GBP,CAD&isAddressEditable=false";
  
    function handleClick() {
      setOpen(true);
      navigator.clipboard.writeText(currentAccount);
      setTimeout(() => {
        setOpen(false);
      }, 600);
    }
  
    useEffect(() => {
      setTimeout(() => {
        fetchNFTOwners();
        setInitialLoading(false);
      }, [1500]);
    }, []);
  
    return (
      <>
        <div className="balance-address-header">
          <div id="page-wrap">
            <Container>
              <Row>
                <Col lg="6" md="6" className="balance-headers">
                  <Row className="correccion">
                    <Col className="d-flex flex-row align-items-center space-evenly-between ">
                      <label style={{ fontWeight: 700, color: '#A5A5A5' }}>Balance</label>
                    </Col>
                    <Col className="d-flex flex-row align-items-center space-evenly-between justify-content-center">
                      <Link to="/my-nfts">
                        <img
                          src={DTCMini}
                          width="40px" height="40px"
                          alt="Nfts"
                        />
                        <label style={{ fontWeight: 700, color: '#002F7C' }}>&nbsp;{nfts}</label>
                      </Link>
                    </Col>
                    <Col className="d-flex flex-row align-items-center space-evenly-between justify-content-center">
                      <Link to="/balance">
                        <img
                          src={MaticMini}
                          width="40px" height="40px"
                          alt="Matic"
                        />
                        <label style={{ fontWeight: 700, color: '#002F7C' }}>
                          &nbsp;{balanceInMatic.toString().slice(0, 6)}
                        </label>
                      </Link>
                    </Col>
                  </Row>
                </Col>
                <Col lg="2" md="1" className="d-xl-block d-lg-block d-md-block d-none"></Col>
                <Col lg="4" md="5" className="d-xl-block d-lg-block d-md-block d-none">
                  <Stack style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <label style={{ fontWeight: 500, color: '#002F7C' }}>My Address</label>
                  </Stack>
                  <Stack direction="horizontal" className="AdressAndLogos">
                  <label onClick={handleClick}>{currentAccount.slice(0,7)}...{currentAccount.slice(35)}</label>
                <Tooltip title="Copied" open={open} disableFocusListener disableHoverListener disableTouchListener>
                  <img src={CopyIcon} alt='copy' onClick={handleClick} style={{marginLeft: '1rem', marginRight: '1rem'}}/>
                </Tooltip>
                {/* <CompartirAddressTelegram _url={`${currentAccount}  https://walletdtc.com/address/${currentAccount}`}/> */}
                <PwaShare _address={currentAccount}/>
                {/*<div><CompartirAddresshatsapp _url={`${currentAccount}  https://walletdtc.com/address/${currentAccount}`}/></div>*/}

                  </Stack>
                </Col>
              </Row>
            </Container>
  
          </div>

        </div>
      </>
    );
  };

export default BalanceHeader