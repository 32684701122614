import React, { useContext, useState } from "react";
import './address.css'
import { Container, Stack } from 'react-bootstrap'
import { UserContext } from "../../context/userContext"; 
import FooterOpcionesHome from '../footer-al-home/footer-al-home';
import { QRCode } from 'react-qrcode-logo';
import Logo from '../../assets/dtc-logo.jpg'
import { useParams, useNavigate } from 'react-router-dom';
import NavigationLanding from '../navigation-landingpage/Navigation-Landing';
import Tooltip from '@mui/material/Tooltip';
import CopyIcon from '../../assets/icons/copy-icon.svg';
import GridLoader from "react-spinners/GridLoader";
import FooterOpciones from '../footer-completo/footer-opciones'
import Navigation from '../navigation/Navigation'
import Button from "react-bootstrap/Button";

const Address = () => {

  let { hash } = useParams();
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const { currentAccount } = useContext(UserContext);

  function handleClick() {
    setOpen(true)
    navigator.clipboard.writeText(hash);
    setTimeout(()=>{
      setOpen(false)
    }, 600)
  }

  const goLogin = () => {
    navigate('/home');
  }

  React.useEffect(() => {
    setTimeout(() => {
      setInitialLoading(false)
  }, [500])
}, [])

  if(initialLoading) {
    return <GridLoader color='#E9E9E9' style={{ position: 'fixed', margin: 'auto', inset: '45%' }} />;
  }else{
    return (
      <>{(currentAccount === "") ?
         <> 
          <NavigationLanding />
          
          <Container className="receive in-the-middle">
          <Stack>
          <h3 style={{ margin:'0 0 15px 0', color: '#F1FAEE'}} >My address</h3>
            <div className="qr-code">
              <QRCode value={hash}size='220' eyeRadius={1} logoImage={Logo} bgColor='transparent' ecLevel='L' fgColor='white'/>
            </div>
          </Stack>

          <Stack className='address'>
            <div style={{ margin:'15px 0'}} className="AdressAndLogos">
            <label onClick={handleClick} style={{ marginRight: '1rem'}}>{hash}</label>
            <Tooltip title="Copied" open={open} disableFocusListener disableHoverListener disableTouchListener>
              <img src={CopyIcon} alt='icon to copy' onClick={handleClick}/>
            </Tooltip>
            </div>
          </Stack>
      
          <div className="form-width" style={{textAlign:'center', marginTop: '1rem'}}>
            <Button onClick={goLogin} className='login-btn' style={{width: '100%'}}>Login | Create your Wallet</Button>
          </div>
      </Container>

      </>
      :
      <> <div>
          <Navigation />
          <Container className="receive in-the-middle">
          <Stack>
          <h3 style={{ margin:'0 0 15px 0', color: '#F1FAEE'}} >My address</h3>
            <div className="qr-code">
              <QRCode value={hash}size='220' eyeRadius={1} logoImage={Logo} bgColor='transparent' ecLevel='L' fgColor='white'/>
            </div>
          </Stack>
          <Stack className='address'>
            <div style={{ margin:'15px 0'}} className="AdressAndLogos">
            <label onClick={handleClick} style={{ marginRight: '1rem'}}>{hash}</label>
            <Tooltip title="Copied" open={open} disableFocusListener disableHoverListener disableTouchListener>
              <img src={CopyIcon} alt='icon to copy' onClick={handleClick}/>
            </Tooltip>
            </div>
          </Stack>
      </Container>
      </div>
      <FooterOpciones /></>
      }
      </>
    )
  }
}

export default Address;