import React from 'react'
import "./CardNFT.css";
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import ReactPlayer from 'react-player'
import Predefinido from '../../assets/icons/512x512.png'
import GridLoader from "react-spinners/GridLoader";
import Moralis from 'moralis';
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import axios from 'axios';

const CardStyled = styled(Card)`
  border-radius: 24px;
  max-width: var(--card-max-width);
  background: #E9E9E9;
  color: #002F7C;
  margin-bottom: 1rem;
  box-shadow: 0px 1px 1px 1px #000000;  
`;

const AuthorTitle = styled("label")`
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  padding-left: 2px;
  padding-right: 2px;
  min-height: 40px;
  max-height: 40px;
  align-items: center;
`;

const CardNFT = ({ id, name, img, address, creator = "", link = '/', uri }) => {
    const [video, setVideo] = React.useState(false)
    const [image, setImage] = React.useState(null)
    const [cargando, setCargando] = React.useState(false)
    
    const Web3Api = useMoralisWeb3Api();
    
    
    const isVideo = async () => {
        try{
            var req = await fetch(img, { method: 'HEAD' });
        }catch(e){
            setImage('https://ipfs.io/ipfs/QmbDz7TiiwtaF2E8x5Ngj2XfjDL8PCh5zPYLv2ctGHxMt2')
        }
        if (req.headers.get('content-type') === 'video/mp4') {
            setVideo(true);
        } else {
            setVideo(false)
        }
    }
    React.useEffect(() => {

        if(uri) get_metadata(uri)

        if(img === undefined){
            setImage('load')
        }
        if(img === 'cargando'){
            setCargando(true)
        }
        else {
            setImage(img)
        }
        isVideo();
       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [img])

    const get_metadata = async (_uri) => {
        console.log('entra a get_metadata para el uri: ', _uri)
        try {
            const headers = {
                "Access-Control-Allow-Origin": "*",
            }
            const json = await axios.get(_uri, { headers: headers } );
            console.log('este es el json: ', json)
        } catch (error) {
            console.log(error)
        }
    }

  return (
      <CardStyled className="hover-up">
            {cargando 
                ? <div style={{height: '170px', width: '100%', backgroundColor: '#2A0173',display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <GridLoader color='#E9E9E9'/> 
                    </div>
                : 
                    <Link to={link}>
                {video
                    ? <ReactPlayer
                        height='170px'
                        url={image && image}
                        width='100%'
                        className='fullscreen-video'
                        loop
                        style={{minHeight: '170px'}}
                        playing
                        muted
                    /> : (img !== 'load') ? <CardMedia
                        component="img"
                        height='170px'
                        image={image && image}
                        style={{minHeight: '170px'}}
                        alt={name} />
                        
                        : <div className='loaderWrapper'><GridLoader color='#E9E9E9' height='170px' /></div>
                    
                }
            </Link>
            }
            <CardContent style={{ padding: '0px', minHeigth: '40px', maxHeight: '40px'}}>
                <Link to={link}>
                    <AuthorTitle>
                        <Typography gutterBottom variant="p" component="div" className=" p-2 pb-0 m-0">
                            {name}
                        </Typography>
                    </AuthorTitle>
                </Link>
            </CardContent>
        </CardStyled >
  )
}

export default CardNFT