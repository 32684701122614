import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserProvider } from "./context/userContext";
import { MoralisProvider } from "react-moralis";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { WCProvider } from "./context/walletConnectContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MoralisProvider appId={process.env.REACT_APP_MORALIS_ID} serverUrl={process.env.REACT_APP_MORALIS_URL}>
      <Router>
        <UserProvider>
          <WCProvider>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </WCProvider>
        </UserProvider>
      </Router>
    </MoralisProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();