import * as React from 'react';
import { useForm } from "react-hook-form";
import { UserContext } from "../../context/userContext";
import { useWalletConnectClient } from '../../context/walletConnectContext';
import './footer-opciones.css';
import { Link } from "react-router-dom";
import NFT from '../../assets/icons/NFT.png';
import Modales from "../../utils/modal/modal";
import Home from '../../assets/icons/home.png';
import Send from '../../assets/icons/Send.png';
import Create from '../../assets/icons/Create.png';
import { Container, Row, Col, Stack } from "react-bootstrap";

import Instagram from '../../assets/icons/instagram icon.png';
import Linkedin from '../../assets/icons/linkedin icon.png';
import Discord from '../../assets/icons/Symbol.png';
import Twitter from '../../assets/icons/Twitter.png'

const FooterOpciones = () => {

  const { register } = useForm()

  const { handleChange } = React.useContext(UserContext);

  const { openTransactionModal, setOpenTransactionModal } = useWalletConnectClient();

  // comunication with modal submit
  const modalToParent = () => {
    setOpenTransactionModal({
      open: false,
      data: openTransactionModal.data,
    });
  };


  return (
    <>

      {/* Modal de transaccion */}
      {(openTransactionModal.open) &&
        <Modales
          modalToParent={modalToParent}
          _title={'Wallet connect transaction'}
          _function={'wallet_connect'}
          _transactionData={openTransactionModal.data}
        ></Modales>}


      <section className='freeze-footer d-xxl-none d-xl-none d-lg-none'>
        <div className="footer-opciones">
          <div>
            <Link to='/home'>
              <div>
                <img src={Home} alt='Home' width='25px' />
                <p style={{ color: 'white' }}>Home</p>
              </div>
            </Link>
          </div>
          <div>
            <Link to='/my-dtcs'>
              <div>
                <img src={NFT} alt='Home' width='25px' />
                <p style={{ color: 'white' }}>DTC</p>
              </div>
            </Link>
          </div>
          <div >
            <label htmlFor='file-upload' className="footer-upload">
              <img src={Create} alt='Home' width='25px' />
              <p style={{ color: 'white' }}>Create</p>
              <input type="file" id='file-upload' onInput={handleChange} style={{ display: 'none' }}{...register('picture')} />
            </label>
          </div>
          <div>
            <Link to='/send'>
              <div>
                <img src={Send} alt='Home' width='25px' />
                <p style={{ color: 'white' }}>Send</p>
              </div>
            </Link>
          </div>
        </div>
      </section>
      <section className='freeze-footer d-none d-lg-block footer-desktop'>
        <div className='footer-opciones'>
          <Container id="footer-container">
            <Row>
              <Col className="d-flex-center-space-evenly px-0 opacity-footer-text" lg={3} xl={3}>
                <a>Privacy policy</a>
                <a>Terms and Conditions</a>
              </Col>
              <Col className="d-flex-middle px-0" lg={6} xl={6}>
                © 2023 DTC Digital Trading Cards, Inc. and respective owners
              </Col>
              <Col className="d-flex-center-space-evenly px-0" lg={3} xl={3} id="rights-reserved">
                <img src={Discord} alt="DTCs" />
                <img src={Instagram} alt="DTCs" />
                <img src={Twitter} alt="DTCs" />
                <img src={Linkedin} alt="DTCs" />
              </Col>
            </Row>
            {/* </Stack> */}
          </Container>
        </div>
      </section>

    </>
  )
}

export default FooterOpciones