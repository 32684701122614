
import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import "./scanner.css";

const Scanner = ({scannerToParent}) => {
  const [data, setData] = useState('');
  const [icon, setIcon] = useState('');

  return (
    <>
     
      <QrReader 
        constraints={{ facingMode: 'environment' }}
        className={"scanner " + (icon === '' ? 'show' : 'hidden')}
        onResult={(result, error) => {
          if (result) {
            setData(result?.text);
            scannerToParent(result?.text)
          }
          else if (String(error).includes('Permission')){
            setData('Habilite permisos y refresque la pagina');
            setIcon(<i className="fa-solid fa-lock"></i>)
          }
          else if (String(error).includes('Requested device not found') || String(error).includes('Could not start video source')){
            setData('No se encontro un dispositivo');
            setIcon(<i className="fa-solid fa-square-xmark"></i>)
          }
          else if (String(error).includes('CanvasRenderingContext2D')){
            setData('Refresque la pagina para volver a intentar');
            setIcon(<i className="fa-solid fa-square-xmark"></i>)
          }
          else if (String(error).includes('getVideoTracks')){
            setData('Refresque la pagina para volver a intentar');
            setIcon(<i className="fa-solid fa-square-xmark"></i>)
          }
          else if (!!error) {
            // console.log(error);
          }
        }}
      />
      <div className="scan-code">
            <span className="scan-code__angle scan-code__angle--top"></span>
            <span className="scan-code__angle scan-code__angle--bottom"></span>
          </div>
      {/* <i className="fa-solid fa-x"></i> */}
      {/* <FontAwesomeIcon icon="fa-solid fa-rectangle-xmark" /> */}
      <div className={(icon === '' ? 'hidden' : 'show')}>
        
          {icon}
        
      </div>
      {/* <p>{data}</p> */}
    </>
  );
};
export default Scanner;