import React from 'react'
import { Box, Tab} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Grid } from '@mui/material'
import AttrChip from '../AttrChip/AttrChip'
import './Tabs.css'


const Tabs = ({hash, stats, rankings, properties}) => {

    const [value, setValue] = React.useState('3');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };


  return (
    <>
    
              {/* <Tabs/> */}
              {/* Menu de Tab que muestra propiedades, stats y levels */}
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value} >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'start', justifyContent: 'center'  }}>
                    <TabList sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', padding: '10px 0px' }}  TabIndicatorProps={{ style: { backgroundColor: "#46B6D9", height: '10px', borderRadius: '50px 50px 0% 0%'}}} onChange={handleChange} aria-label="NFTDetails">
                        <Tab label="Properties" value="3" className="tab-details" style={value === '3' ? {color: 'white'} : { color: 'gray'}}/>
                        <Tab label="Rankings"  value="2" className="tab-details" style={value === '2' ? {color: 'white'} : { color: 'gray'}}/>
                        <Tab label="Stats" value="1" className="tab-details" style={value === '1' ? {color: 'white'} : { color: 'gray'}}/>
                    </TabList>
                    </Box>

                  
                  {/*  Tab de Details  */}
                  <TabPanel value="3">
                  <Grid container='true'>
                  {properties.length > 0 ? properties.map((dato, index) => (
                        <AttrChip prop={dato.trait_type} value={dato.value} />
                      )) : <h6  style={{marginInline: 'auto',  textAlign: 'center'}}>No Properties available</h6>}
                    </Grid>
                  </TabPanel>

                  {/*  Tab de Rankings  */}
                  <TabPanel value="2">
                  <Grid container='true'>
                  {rankings.length > 0 ? rankings.map((dato, index) => (
                        <AttrChip prop={dato.trait_type} value={dato.value} /> 
                      )) : <h6  style={{marginInline: 'auto',  textAlign: 'center'}}>No Rankings available </h6>}  
                    </Grid>
                  </TabPanel>

                  
                  {/*  Tab de Stats  */}
                  <TabPanel value="1" >
                    <Grid container='true'>
                      {stats.length > 0 ? stats.map((dato, index) => (
                        <AttrChip prop={dato.trait_type} value={dato.value} />
                      )) : <h6 style={{marginInline: 'auto', textAlign: 'center'}}>No Stats available</h6>}  
                    </Grid>                 
                  </TabPanel>
                </TabContext>
              </Box>
    </>
  )
}

export default Tabs