/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import LogoDTCSvg from "../../assets/DTC-logo-2021.svg";
import dtcHero from '../../assets/portada.png'
import { Container} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "./Login.css";
import {useNavigate } from "react-router-dom";
import GridLoader from "react-spinners/GridLoader";
//context
import { UserContext } from "../../context/userContext";



const Login = () => {
  const { connect, disconnect, currentAccount, loader } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(()=>{
    if(currentAccount !== ''){
      navigate('/home')
    }
  }, [currentAccount])

  return (
    <div className="centrar-todo">
      <div className='login-first-part'>
      <Container style={{backgroundColor: 'white', display: 'flex', justifyContent: 'center', padding: '2rem'}}>
          <img src={LogoDTCSvg} alt="Dapps Factory" width="60%" className='img-login'/>
      </Container>
      </div>
        <div className='container-login'>
          <img src={dtcHero} alt='' />
          <Button onClick={connect} className='login-button btn-login login-big'>{loader ? <GridLoader size='3px' color='#E9E9E9'/>: 'Login'}</Button> 
        </div>
        
    </div>
  );

};

export default Login;
