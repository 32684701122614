import React, { useContext, useState } from "react";
import "./recent-destinations.css";
import { Container, Stack } from "react-bootstrap";
import { UserContext } from "../../context/userContext";
import { Link } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';

const RecentDestinations = ({ param, _title, setReceiver }) => {
  const { contacts } = useContext(UserContext);
  const [open, setOpen] = useState(false)
  const [contactIndex, setContactIndex] = useState(0)

  function handleCopy(address, index) {
    setContactIndex(index)
    setOpen(true)
    navigator.clipboard.writeText(address);
    // alert('copied');
    setTimeout(()=>{
      setOpen(false)
    }, 500)
    handlePaste();
  }

  function handlePaste() {
      navigator.clipboard
    .readText()
    .then((clipText) => setReceiver(clipText));
  }



  return (
    <>
      {contacts.length >0 &&<Container>
        <h5 className="titleh5">Recently Added</h5>

        <Stack className="recent-destinations">
          {contacts.slice(-4).map((contact, index) => (
            contact.attributes?.isActive === true &&
            <Tooltip title='Copied' open={contactIndex === index && open } key={index} disableFocusListener disableHoverListener disableTouchListener>
              <div className="contact" key={contact.attributes.contactAddress} onClick={(e) => handleCopy(contact.attributes.contactAddress, index)}>
                <div className="circle">{contact.attributes.contactName.slice(0, 2)}</div>
                <p>{contact.attributes.contactName}</p>
                <p>{contact.attributes.contactAddress.slice(0, 3)}... {contact.attributes.contactAddress.slice(39)}</p>
              </div>
            </Tooltip>
          )).reverse()}
          <div className="contact">
            {(param === "send" && contacts !== []) && (
              <Tooltip title='Agenda'>
                <Link to="/agenda">
                  <div className="circle">
                    <i className="fa-solid fa-address-book"></i>
                  </div>
                  <p>View</p>
                  <p>More</p>
                </Link>
              </Tooltip>
            )}
            {(param === "agenda" || (contacts === [] && param === "send")) && (
              <Tooltip title='Add Contact'>
                <Link to="/add-contact">
                  <div className="circle addContact">
                    <i className="fa-solid fa-user-plus"></i>
                  </div>
                  <p>Add</p>
                  <p>Contact</p>
                </Link>
              </Tooltip>
            )}
          </div>
        </Stack>
      </Container>}
    </>
  );
};

export default RecentDestinations;
