/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext} from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { UserContext } from "../context/userContext"; 

const RequireConnected = () => {
    const [loading, setLoading] = React.useState(true)
    const { currentAccount } = useContext(UserContext);
    const location = useLocation();
    React.useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, [300])
    }, [])

    // Componente de loading
    if (loading) {
        return <></>
    } else {
        if (currentAccount === "") {
            return <Navigate to="/login" state={{ from: location }} replace />
        } else {
            return <Outlet />
        }
    }
}

export default RequireConnected