import React, {useContext} from 'react'
import { Link } from 'react-router-dom';
import Navigation from '../navigation/Navigation';
import GoBack from '../goBack/GoBack'
import './SendSelect.css'
import {Container, Col, Row, Stack} from 'react-bootstrap'
import DTCBig from '../../assets/DtcBtnBig.svg'
import MaticBig from '../../assets/MaticBtnBig.svg'
import { UserContext } from "../../context/userContext";
import FooterOpciones from '../footer-completo/footer-opciones';
import DtcBig from '../../assets/DtcBtnBig.svg'
import BalanceHeader from '../balance-header/BalanceHeader';


const SendSelect = () => {
  const {   balanceInMatic, nfts  } = useContext(UserContext);

  return (
    <>
    <div className='min-height-margin'>
        <Navigation pageWrapId={"page-wrap"} outerContainerId={"Home"} />

        <BalanceHeader />

        <GoBack value="Send" link="/home" />
        
          

        <Container
          className="d-flex flex-column align-items-center justify-content-evenly"
          style={{ minHeight: "calc(50vh - 0px)" }}
        >
          <h4 style={{ margin:'0 0 15px 0', fontSize: '20px', textAlign: 'center' }}> Select the token type to send</h4>
                <Row style={{textAlign: 'center'}}>
                    <Col lg="6">
                    <Link to='/my-dtcs/send'>
                            <img src={DtcBig} alt="DTCs"  />
                    </Link>
                    </Col>
               
                    <Col lg="6" className='mt-3'>
                    <Link to='/send-token'>
                            <img src={MaticBig} alt="DTCs" />    
                    </Link>
                    </Col>
                </Row>
                
            </Container>
            <FooterOpciones/>
    </div>
    </>
  )
}

export default SendSelect