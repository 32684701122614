import React, { useContext, useState } from "react";
import './Receive.css'
import { Container, Stack } from 'react-bootstrap'
import Navigation from '../navigation/Navigation';
import GoBack from '../goBack/GoBack';
import { UserContext } from "../../context/userContext"; 
import PwaShare from '../../utils/pwa-share/pwa-share';
//import QRCode from "react-qr-code";
import FooterOpciones from "../footer-completo/footer-opciones";
import { QRCode } from 'react-qrcode-logo';
import Logo from '../../assets/dtc-logo.jpg'
import {CompartirAddresshatsapp, CompartirAddressTelegram} from "../../utils/Share-social"
import CopyIcon from '../../assets/icons/copy-icon.svg';
import Tooltip from '@mui/material/Tooltip';


const Receive = () => {

  const { currentAccount } = useContext(UserContext);
  const [open, setOpen] = useState(false)


  
  function handleClick() {
    setOpen(true)
    navigator.clipboard.writeText(currentAccount);
    setTimeout(()=>{
      setOpen(false)
    }, 600)
  }

  return (
    <>
     <div style={{ minHeight: "calc(100vh - 66px)", marginBottom: "66px"}}>
    <Navigation />
    <GoBack value="Receive" link='/home'/>
    <Container className="receive in-the-middle">
        <Stack className='d-flex align-items-center'>
        <h3 style={{ margin:'0 0 15px 0', color: '#F1FAEE'}} > My address</h3>
            <QRCode value={currentAccount} size='220' eyeRadius={1} logoImage={Logo} bgColor='transparent' ecLevel='L' fgColor='white'/>
          
        </Stack>
        <Stack className='address mt-3'>
        <span onClick={() => navigator.clipboard.writeText([currentAccount])} style={{margin: '0 2rem'}}>{currentAccount}</span>
        

          <div style={{ margin:'1rem 0'}} >
          <Tooltip title="Copied" open={open} disableFocusListener disableHoverListener disableTouchListener>
            <img src={CopyIcon} alt='icon to copy' onClick={handleClick}/>
          </Tooltip>
            &nbsp;&nbsp;
            <PwaShare _address={currentAccount}/>
            {/* <CompartirAddressTelegram _url={`${currentAccount}  https://walletdtc.com/address/${currentAccount}`}/> */}
          </div>
        </Stack>
     </Container>
     </div>
     <FooterOpciones/>
     
    </>
  )
}

export default Receive