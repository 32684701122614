import React from 'react'
import Moralis from 'moralis';
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import GridLoader from "react-spinners/GridLoader";
import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import './CardLoadMetadata.css';

const CardStyledReload = styled(Card)`
  border-radius: 24px;
  max-width: var(--card-max-width);
  background: #2A0173;
  min-height: 210px;
  color: #FFFFFF;
  margin-bottom: 1rem;
  box-shadow: 0px 1px 1px 1px #000000;
  display: flex;
  justify-content: center;
  align-items: center;  
`;


const CardLoadMetadata = ({_tokenAddress, _tokenId}) => {
    const [loading, setLoading] = React.useState(false)
    const Web3Api = useMoralisWeb3Api();

    
    const reSyncMetadata = async (_tokenAddress, _tokenId) => {
        setLoading(true)
        console.log(_tokenAddress, _tokenId)
        const options = {
          address: _tokenAddress,
          chain: Moralis.Chains.POLYGON_MAINNET,
          token_id: _tokenId,
          flag: "metadata",
        };
        const result = await Web3Api.token.reSyncMetadata(options);
        console.log(result);
        alert('request initiated, this may take a while.')
      };


  return (
    <CardStyledReload >
        <button onClick={()=>reSyncMetadata(_tokenAddress, _tokenId)} className='btn-login'>{loading ? <GridLoader color='#E9E9E9' style={{ fontSize: '0.5rem'}} /> : 'Reload metadata'} </button>
    </CardStyledReload>
  )
}

export default CardLoadMetadata