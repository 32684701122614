/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./MisNFT.css";
import CardNFT from '../../utils/CardNFT/CardNFT'
import CardLoadMetadata from '../../utils/CardLoadMetadata/CardLoadMetadata'
import { Container, Row, Col } from "react-bootstrap";
import Navigation from "../navigation/Navigation";
import usePagination from "../../utils/pagination/pagination"
import GoBack from "../goBack/GoBack";
import FooterOpciones from '../footer-completo/footer-opciones'
import GridLoader from "react-spinners/GridLoader";
import Pagination from '@mui/material/Pagination';
import DTC from '../../assets/noDTCtransparent.png'


//context
import { UserContext } from "../../context/userContext";
import BalanceHeader from "../balance-header/BalanceHeader";

const MisNft = () => {
  const { listOfNft, provider, fetchNFTOwners } = useContext(UserContext);
  const urlParams = useParams()
  const url = urlParams["*"]

  //trim the URL from useParams hook
  const fields = url.split('/');
  const index = fields[0] + '/' + fields[1];
  
  const [initialLoading, setInitialLoading] = useState(true);

  // Pagination
  const [page, setPage] = React.useState(1);
  const limit = 12;
  const _DATA = usePagination(listOfNft, limit);
  
  const [paginationLength, setPaginationLength] = useState(1);

  const handlePaginationChange = (event, value) =>{
    setInitialLoading(true)
    setPage(value);
    _DATA.jump(value)
    setTimeout(() =>{
      setInitialLoading(false)
    }, 1000)

  }

  useEffect(() => {
    setTimeout(() => {
      fetchNFTOwners();
      console.log(listOfNft)
    }, [1500])
  }, [])

  useEffect(() => {
      if(listOfNft.length >= 0){
        setPaginationLength(listOfNft.length)
        setTimeout(() => {
          setInitialLoading(false);
        }, [1000])
      }
  }, [provider])



 
  //sacar el "!"
  if (initialLoading) {
    return <GridLoader color='#E9E9E9' style={{position: 'fixed',margin: 'auto', inset: '45%'}}/>;
  } else {
    return (
      <>
        <div className='min-height-margin'>
          <Navigation />
          <BalanceHeader />
          <GoBack value={index === 'my-dtcs/send' ? 'Select DTC to Transfer' : 'My DTCs'} link={index === 'my-dtcs/send' ? '/send' : '/home'}/>
          <Container className="mis-nft ">
          <Row className="row-cards mt-5">
            {listOfNft.length > 0 ?  _DATA.currentData().map((nft, index) => (
                  (nft?.metadata === null && nft?.token_uri !== null) ?
                    <>
                    <Col xs={6} sm={6} md={4} lg={3} xl={2}  key={nft.token_hash}>
                      <CardNFT id={nft.token_hash} creator='creator' name={"We don't have the content for this DTC yet. Check back later."} img={"cargando"} address={nft.token_address} />
                    </Col>
                  </>
                  :
                    (nft.metadata === null && nft.token_uri === null) ?
                      <>
                      <Col xs={6} sm={6} md={4} lg={3} xl={2}  key={nft.token_hash}>
                        <CardLoadMetadata _tokenId={nft.token_id} _tokenAddress={nft.token_address}/>
                      </Col>
                      </>
                      :
                        <>
                          <Col xs={6} sm={6} md={4} lg={3} xl={2}  key={nft.token_hash}>
                            {/*<CardNFT key={nft.token_hash} id={nft.token_hash} creator='creator' name={JSON.parse(nft?.metadata)?.name} img={(((JSON.parse(nft?.metadata)?.image)?.slice(0,7)) === "ipfs://") ? "https://ipfs.io/ipfs/" + ((JSON.parse(nft?.metadata)?.image)?.slice(7)) :  (((JSON.parse(nft?.metadata)?.image)?.slice(0,12)) === "ipfs://ipfs/") ? "https://ipfs.io/" + ((JSON.parse(nft?.metadata)?.image)?.slice(12)) : JSON.parse(nft?.metadata)?.image} link={url === 'my-dtcs' ? `/my-dtcs/${nft.token_hash}` : `/my-dtcs/send/${nft.token_hash}`} />*/}
                            <CardNFT id={nft.token_hash} creator='creator' name={JSON.parse(nft?.metadata)?.name} img={(((JSON.parse(nft?.metadata)?.image)?.slice(0,12)) === "ipfs://ipfs/") ? "https://ipfs.io/ipfs/" + ((JSON.parse(nft?.metadata)?.image)?.slice(12)) :  (((JSON.parse(nft?.metadata)?.image)?.slice(0,7)) === "ipfs://") ? "https://ipfs.io/ipfs/" + ((JSON.parse(nft?.metadata)?.image)?.slice(7)) : JSON.parse(nft?.metadata)?.image} link={url === 'my-dtcs' ? `/my-dtcs/${nft.token_hash}` : `/my-dtcs/send/${nft.token_hash}`} />
                          </Col>
                        </>
              ))
              
              : 
              <>
                <div style={{minHeight: '50vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly'}}>
                  <img src={DTC} alt='DTC' />
                  <p style={{fontSize: '21px'}}>STILL NO DTCS HERE</p>
                </div>
              </>
              }  
            </Row>
            <Row>
              {listOfNft.length === 0 ? null : <Pagination count={Math.max(Math.ceil(paginationLength / limit), 1)} page={page} onChange={handlePaginationChange} color="primary" />}
            </Row>
           
          </Container>
        </div>
        <FooterOpciones />
      </>
    );
  }
};

export default MisNft;
