import React from 'react'
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

const Test = ({_totalPrice, _nftData, _signature, _moralisId}) => {
  return (
    <>
            <CrossmintPayButton

            clientId="eae40165-848e-4b88-bd0e-36ef555cf40a"

            mintConfig={{"type":"erc-1155",
            "totalPrice": "1",
            "_nftData": ["https://digitaltradingcards.mypinata.cloud/ipfs/QmTpQ6dkYqBLobWCgDZDTtDfZzgqPo5geJ1uJjNehHmQeX", 
          "0xb94616161f1734ce7e621c458042059d9de1744e",
          "1667349148247",
          "0x4d6d4f1292ece94a4f0c9ea541de4cb1e4b5ca9f"],
            "signature": "0x686e9a3dad25c257514300d55d0d465f7dcd7d2a915222e01808fbd8a4c26f74693cfaed960099ef71a849dab83b351336d01bcf1d3d86dbac9e8fcf7a68c4af1c",
            "_moralisId": "ID",
            "_tokenAmount": "1",
            "_maxSupply": "100"}}

            />
    </>
  )
}

export default Test