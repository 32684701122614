import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Container, Col, Row, Stack, Button} from 'react-bootstrap'
import Checkbox from '@mui/material/Checkbox';
import {GiCheckMark} from 'react-icons/gi'
import Header from "../../assets/header.png";
import Logo from '../../assets/PoC.png'
import Big from '../../assets/landing/imagenBajaDTC.png'
import './FirstTime.css'

const FirstTime = () => {
    const [checked, setChecked] = React.useState(false);
    let navigate = useNavigate();

    const handleChange = (event) => {
        setChecked(event.target.checked);
      };

    const handleClick = () =>{
        navigate('/home')
    }

  return (
   <>
        <div style={{paddingTop: '1rem', backgroundColor: 'white', paddingBottom: '1rem', textAlign: 'center'}}>
                <img src={Header} alt='' style={{width: '40%', maxWidth: '300px', minWidth: '240px'}}/>
        </div>
    <Container>
        <Row>
            <Col>
            <Stack>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem'}}>
                    <img src={Big} alt='' className='first-img'/>

                </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'start', marginTop: '1rem'}}>
                <ul className='list-ul'>
                    <li className='li-login'><GiCheckMark className='check-login'/> &nbsp;View DTC's</li>
                    <li className='li-login'><GiCheckMark className='check-login'/> &nbsp;Create DTC's</li>
                    <li className='li-login'><GiCheckMark className='check-login'/> &nbsp;Send DTC's</li>
                    <li className='li-login'><GiCheckMark className='check-login'/> &nbsp;Share DTC's with friends</li>
                </ul>
            </div>
              </Stack>
            </Col>
        </Row>
        <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
                &nbsp;&nbsp;<span>I have read and agree the <a href='/termsandconditions' style={{color: '#6bcdff'}}>Terms and Conditions</a> and the <a href='/privacypolicy'  style={{color: '#6bcdff'}}>Privacy Policy</a></span>
            </div>
            <div style={{width: '80%', display: 'flex', justifyContent: 'center'}}>
                <Button className='login-button btn-login' disabled={!checked} style={{color: 'white', fontWeight: 'bold', marginTop: '1rem', width: '80%', marginBottom: '2rem'}} onClick={handleClick}>
                    accept
                </Button>
            </div>
        </Row>
    </Container>
   </>
  )
}

export default FirstTime