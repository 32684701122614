import React, { useContext, useState } from "react";
import Moralis from 'moralis';
import { ethers } from 'ethers'
import "./Add-contact.css";
import { Container, Stack, Form, Button } from "react-bootstrap";
import { UserContext } from "../../context/userContext";
import Navigation from "../navigation/Navigation";
import GoBack from "../goBack/GoBack";
import FooterOpciones from "../footer-completo/footer-opciones";
import InputGroup from 'react-bootstrap/InputGroup';
import Scanner from "../scanner/scanner";

import Modales from "../../utils/modal/modal";

const AddContact = () => {
  const { currentAccount } = useContext(UserContext);
  const [name, setName] = useState('')
  const [address, setAddress] = useState("");
  const [isShown, setIsShown] = useState(false);
  const [modalIsShown, setModalIsShown] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);


  // open and comunication of camara
  const handleCam = event => {
    if (isShown === true) {
      setIsShown(false);
    }
    else {
      setIsShown(true);
      // setChildData("");
      setAddress("");
      document.getElementById('addressAddContact').value = "";
    }
  }

  const camaraToAddContact = (dataReceiveFromScanner) => {
    if (isShown) {
      setAddress(dataReceiveFromScanner);
      document.getElementById('addressAddContact').value = dataReceiveFromScanner;
    }
  };

  // saveContact
  async function saveContact(name, address) {
    
    await Moralis.Cloud.run('SaveContact', { contactName: name, contactAddress: address, wallet: currentAccount, id: Date.now() })
      .then(response => { console.log(response) })
  }

  // form submit 
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() && ethers.utils.isAddress(address)) {
      setModalIsShown(true);
      saveContact(name, address)
    }
    event.preventDefault();
    event.stopPropagation();
    setFormSubmitted(true);

  };


  // comunication with modal submit
  const modalToParent = () => {
    setModalIsShown(false);
  };

  return (
    <>
      <div className='height-all-less-footer'>
        <Navigation />
        <GoBack value="Agenda" link='/agenda'/>
        <Container className="in-the-middle">
          <Stack className="add-contact">
            <i className="fa-solid fa-user-plus"></i>
            <h5 className="mt-3">Add Contact</h5>
            <Form className="max-width-form" noValidate id="addContactForm" onSubmit={handleSubmit}>
              <Form.Group className="mb-2 mt-2">
                <InputGroup className="mb-3 mt-2" hasValidation>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    required
                    name="name"
                    id="name"
                    onChange={(e) => {
                      setName(e.target.value);
                      
                    }}
                    isInvalid={(name.length === 0) && (name.length || formSubmitted)}
                    isValid={(name.length > 0)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Complete the field
                  </Form.Control.Feedback>
                </InputGroup>
                <InputGroup hasValidation className="mb-4 mt-3">
                  <Form.Control
                    type="text"
                    placeholder="Address"
                    required
                    id="addressAddContact"
                    // defaultValue={childData !== '' ? childData : address}
                    // value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    isInvalid={!ethers.utils.isAddress(address) && (address.length || formSubmitted)}
                    isValid={ethers.utils.isAddress(address)}
                  />
                  <InputGroup.Text id="basic-addon1" onClick={handleCam}><i className="fa-solid fa-camera"></i></InputGroup.Text>
                  <Form.Control.Feedback type="invalid">Invalid Address</Form.Control.Feedback>
                </InputGroup>
                {(isShown) && (
                  <Scanner scannerToParent={camaraToAddContact}/>
                )}
                <Button className="max-width-form w-100 mt-3 send-button" type="submit">Add</Button>
                {(modalIsShown) &&
                  <Modales modalToParent={modalToParent}
                    _text={"¡Contact saved! \n" + 
                    "Name: " + name + "\n" +
                    "Address: " + address.slice(0,7) + "..." + address.slice(37)}
                    _function=""
                    _icon = "check"
                  ></Modales>
                }
              </Form.Group>
            </Form>
          </Stack>
        </Container>
      </div>
      <FooterOpciones />
    </>
  );
};

export default AddContact;
