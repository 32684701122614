import * as React from 'react'
import { Container } from 'react-bootstrap'
import ConnectScanner from '../components/wallet-connect/ConnectScanner'
import FooterOpciones from '../components/footer-completo/footer-opciones'
import GoBack from '../components/goBack/GoBack'
import Navigation from '../components/navigation/Navigation'
import RecentDestinations from '../components/recent-destinations/recent-destinations'
import ConnectInput from '../components/wallet-connect/ConnectInput'
import { useWalletConnectClient } from '../context/walletConnectContext'
import WalletsConnected from '../components/wallet-connect/WalletsConnected'
import SessionProposal from '../components/wallet-connect/SessionProposal'

const ConnectWallet = () => {

  const { signClient, openSessionModal, legacySignClient } = useWalletConnectClient();

  return (
    <>
      <div className='height-all-less-footer'>
        <Navigation />
        <GoBack value="Connect wallet" link='/home' />
        <RecentDestinations param="connect-wallet" />
        <Container className="p-4">
          {
            openSessionModal.open
              ? <SessionProposal />
              : signClient?.session.values.length > 0
                ? <WalletsConnected _sessions={signClient?.session.values} _version={2} />
                : legacySignClient?.session.connected
                  ? <WalletsConnected _sessions={[legacySignClient.session]} _version={1} />
                  : <>
                    <ConnectScanner />
                    <ConnectInput />
                  </>
          }
        </Container>
      </div>
      <FooterOpciones />
    </>
  )
}

export default ConnectWallet