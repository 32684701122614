import React from 'react'
import axios from 'axios'
import { ethers } from "ethers";
import ReactPlayer from 'react-player'
import BootstrapInput from "../../utils/Inputs/BootstrapInput";
// Formulario
import { Container, Row, Col } from "react-bootstrap";
import { Button, Stack, Grid, MenuItem, Select, CircularProgress, Alert , Typography, Tab, Box, IconButton} from '@mui/material'
// tabs
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import './UploadCsv.css'
// Iconos para properties
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ImageIcon from '@mui/icons-material/Image';


const UploadCsv = () => {

    // Variables del formulario
    const [nombre, setNombre] = React.useState("");
    const [desc, setDesc] = React.useState("");
    const [file, setFile] = React.useState('');
    const [category, setCategory] = React.useState("");
    const [collection, setCollection] = React.useState("");
    const [oldImg, setOldImg] = React.useState("")
    const [video, setVideo] = React.useState(false)
    // Lista de categorías y colecciones
    const [categories, setCategories] = React.useState([])
    const [collections, setCollections] = React.useState([])
    const [collectionAddress, setCollectionAddress] = React.useState("")
    // NFT ya subido a Moralis
    const [success, setSuccess] = React.useState(false);
    const handleClose = () => setSuccess(false);
    const [error, setError] = React.useState(false)
    // Variables para el tab de atributos y propiedades
    const [tabValue, setValue] = React.useState("1");
    const handleTabChange = (event, newValue) => {
      setValue(newValue);
    };
    // Deshabilitar boton
    const [disable, setDisable] = React.useState(false)
    const [loadingSpinner, setLoadingSpinner] = React.useState(false)
    // Variables para los atributos
    const [attributes, setAttributes] = React.useState([]);
    const [properties, setProperties] = React.useState([]);
    const [stats, setStats] = React.useState([]);
    const [rankings, setRankings] = React.useState([]);



  const uploadNFT = async (e) => {
    await uploadImage(e)
  }

  // a) Sube la imagen
  const uploadImage = async (e, estado) => {
    
    const data = new FormData();
    data.append("file", file);
    axios
      .post(`https://api.pinata.cloud/pinning/pinFileToIPFS`, data, {
        maxBodyLength: "Infinity",
        headers: {
          pinata_api_key: process.env.REACT_APP_PINATA_KEY,
          pinata_secret_api_key: process.env.REACT_APP_PINATA_SECRET,
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((e) => {
        console.error(e);
      });
  
};

  // Escucha los cambios de imagenes
  const onChangeImg = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  // Funciones para las propiedades
  const handlePropChange = (i, e) => {
    let newFormValues = [...properties];
    newFormValues[i][e.target.name] = e.target.value;
    setProperties(newFormValues);
  }
  const removeProperties = (i) => {
    let newFormValues = [...properties];
    var index = newFormValues.map(values => {
      return values.id;
    }).indexOf(i);
    newFormValues.splice(index, 1);
    setProperties(newFormValues)
  }
  const addProperties = () => {
    setProperties([...properties, { "trait_type": "", "value": "", "id": Date.now() }]);
  }

  // Funciones para las Stats
  const handleStatChange = (i, e) => {
    let newFormValues = [...stats];
    newFormValues[i]["display_type"] = "number";
    if (e.target.name === "trait_type") newFormValues[i]["trait_type"] = e.target.value;
    if (e.target.name === "value") newFormValues[i]["value"] = Number(e.target.value);
    setStats(newFormValues);
  }
  const removeStats = (i) => {
    let newFormValues = [...stats];
    var index = newFormValues.map(values => {
      return values.id;
    }).indexOf(i);
    newFormValues.splice(index, 1);
    setStats(newFormValues);
  }
  const addStats = () => {
    setStats([...stats, { "display_type": "number", "trait_type": "", "value": Number("0"), "id": Date.now() }]);
  }

  // Funciones para los rankings
  const addRankings = () => {
    setRankings([...rankings, { "trait_type": "", "value": Number("0"), "id": Date.now() }]);
  }
  const removeRanking = (i) => {
    let newFormValues = [...rankings];
    var index = newFormValues.map(values => {
      return values.id;
    }).indexOf(i);
    newFormValues.splice(index, 1);
    setRankings(newFormValues);
  }
  const handleRankingChange = (i, e) => {
    let newFormValues = [...rankings];
    if (e.target.name === "trait_type") newFormValues[i]["trait_type"] = e.target.value;
    if (e.target.name === "value") newFormValues[i]["value"] = Number(e.target.value);
    setRankings(newFormValues);
  }

  // Cuando algun array cambia:
  React.useEffect(() => {
    let newAttributes = [...stats, ...properties, ...rankings];
    setAttributes(newAttributes);
  }, [properties, stats, rankings])


  return (
    <>
        <Container>
      <Typography variant="h4" component="h1" color="primary" className="mt-4 mb-3"> Crear NFT</Typography>
        <Stack spacing={4}>
          {/* Imagen */}
          <label htmlFor="fileInput" className="custom-file-upload">
          {file
              ? video
                ? <ReactPlayer height='100%' width='100%' url={URL.createObjectURL(file)} loop playing muted />
                : <img src={URL.createObjectURL(file)} width="auto" height="245px" alt="imagen" style={{ margin: "1rem", maxWidth: '340px' }} />
              : <><ImageIcon color="disabled" sx={{ fontSize: '4rem' }} /><Typography variant="h6" sx={{ color: '#999999' }}>Imagen</Typography></>}
            <input type="file" accept=".jpg,.jpeg,.png,.svg,.gif,.mp4" name="fileInput" id="fileInput" onChange={(e) => onChangeImg(e)} />
          </label>

          {/* Resto del formulario */}
          <Stack spacing={4}>
            {/* Nombre del NFT */}
            <div className="d-flex flex-column">
              <BootstrapInput placeholder='Nombre de NFT *' type="text" onChange={(e) => setNombre(e.target.value)} />
            </div>

            {/* Descripcion */}
            <div className="d-flex flex-column">
             <BootstrapInput placeholder="Descripción del NFT" type="text" multiline rows="4" onChange={(e) => setDesc(e.target.value)} />
            </div>

            {/* <Tabs /> */}
            {/* Menu de Tab para propiedades, stats y levels */}
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
              <TabContext value={tabValue}>
                {/* Header */}
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleTabChange} aria-label="properties tab" >
                    <Tab label="Propiedades" value="1" />
                    <Tab label="Stats" value="2" />
                    <Tab label="Rankings" value="3" />
                  </TabList>
                </Box>

                {/*  Tab de propiedades  */}
                <TabPanel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }} value="1" className="px-0">
                  {properties.length > 0 && properties.map((element, index) => (
                    <div className="form-inline mb-3" key={element.id}>

                      <div className="d-inline mx-2">
                        <label className="mx-2"><Typography variant="button" component="span">Key:</Typography></label>
                        <BootstrapInput type="text" name="trait_type" defaultValue={element.trait_type} onChange={e => handlePropChange(index, e)} sx={{ '& .MuiInputBase-input': { fontSize: '14px' } }} />
                      </div>
                      <div className="d-inline mx-2">
                        <label className="mx-2"><Typography variant="button" component="span">Valor:</Typography></label>
                        <BootstrapInput type="text" name="value" defaultValue={element.value} onChange={e => handlePropChange(index, e)} sx={{ '& .MuiInputBase-input': { fontSize: '14px' } }} />
                      </div>
                      <IconButton type="button" onClick={() => removeProperties(element.id)}><RemoveCircleOutlineIcon /></IconButton>
                    </div>
                  ))}
                  <Button type="button" variant="contained" size="large" sx={{ fontSize: '1rem', marginRight: "2rem", marginTop: "1rem" }} onClick={() => addProperties()}>
                    <AddCircleOutlineIcon style={{ marginRight: '10px' }} />
                    Agregar Propiedades
                  </Button>
                </TabPanel>

                {/* Tab de Stats */}
                <TabPanel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }} value="2">
                  {stats.length > 0 && stats.map((element, index) => (
                    <div className="form-inline mb-3" key={element.id}>
                      <div className="d-inline mx-2">
                        <label className="mx-2"><Typography variant="button" component="span">Key:</Typography></label>
                        <BootstrapInput type="text" name="trait_type" defaultValue={element.trait_type} onChange={e => handleStatChange(index, e)} sx={{ '& .MuiInputBase-input': { fontSize: '14px' } }} />
                      </div>
                      <div className="d-inline mx-2">
                        <label className="mx-2"><Typography variant="button" component="span">Valor:</Typography></label>
                        <BootstrapInput type="number" name="value" defaultValue={element.value} onChange={e => handleStatChange(index, e)} sx={{ '& .MuiInputBase-input': { fontSize: '14px' } }} />
                      </div>
                      <IconButton type="button" onClick={() => removeStats(element.id)}><RemoveCircleOutlineIcon /></IconButton>
                    </div>
                  ))}
                  <Button type="button" variant="contained" size="large" sx={{ fontSize: '1rem', marginRight: "2rem", marginTop: "1rem" }} onClick={() => addStats()}>
                    <AddCircleOutlineIcon style={{ marginRight: '10px' }} />
                    Agregar Stat
                  </Button>
                </TabPanel>

                {/* Tab de Rankings */}
                <TabPanel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }} value="3">
                  {rankings.length > 0 && rankings.map((element, index) => (
                    <div className="form-inline mb-3" key={element.id}>
                      <div className="d-inline mx-2">
                        <label className="mx-2"><Typography variant="button" component="span">Key:</Typography></label>
                        <BootstrapInput type="text" name="trait_type" defaultValue={element.trait_type} onChange={e => handleRankingChange(index, e)} sx={{ '& .MuiInputBase-input': { fontSize: '14px' } }} />
                      </div>
                      <div className="d-inline mx-2">
                        <label className="mx-2"><Typography variant="button" component="span">Valor:</Typography></label>
                        <BootstrapInput type="text" name="value" defaultValue={element.value} onChange={e => handleRankingChange(index, e)} sx={{ '& .MuiInputBase-input': { fontSize: '14px' } }} />
                      </div>
                      <IconButton type="button" onClick={() => removeRanking(element.id)}><RemoveCircleOutlineIcon /></IconButton>
                    </div>
                  ))}
                  <Button type="button" variant="contained" size="large" sx={{ fontSize: '1rem', marginRight: "2rem", marginTop: "1rem" }} onClick={() => addRankings()}>
                    <AddCircleOutlineIcon style={{ marginRight: '10px' }} />
                    Agregar Ranking
                  </Button>
                </TabPanel>
              </TabContext>
            </Box>

          
          </Stack>

          <Grid container direction="row" justifyContent="start" alignItems="center" className="my-3">
            {loadingSpinner ? <CircularProgress color="primary" sx={{ margin: '4rem' }} />
              : <>
                
                <Grid>
                  {
                    (file || oldImg !== "")?

                      <Button type="submit" color="secondary" variant="contained" onClick={(e) => uploadNFT(e)} sx={{ fontSize: '1rem', marginRight: "2rem", marginTop: "2rem" }}>
                        UPLOAD NFT
                      </Button>
                      :
                      <Button type="submit" disabled={true} color="secondary" variant="contained" sx={{ fontSize: '1rem', marginRight: "2rem", marginTop: "2rem" }}>
                        UPLOAD NFT
                      </Button>
                  }
                </Grid>
              </>}
          </Grid>
          {error && <div className="d-flex justify-content-center align-items-center"><Alert severity="error" sx={{ marginTop: '2rem', fontSize: '1.2rem', display: 'flex', alignItems: 'center' }}>Error en la carga del NFT</Alert></div>}
        </Stack>
    </Container>
    </>
    
  )
}

export default UploadCsv