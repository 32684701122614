import React, { useContext, useState } from 'react'
import './Navigation.css'
import { Container, Row, Col, NavDropdown } from 'react-bootstrap'
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import Burger from '../../assets/burger-menu.png'
import Installpwa from "../../utils/InstalPWA/instalpwa";
import Header from "../../assets/header.png";
import { UserContext } from "../../context/userContext";

const Navigation = () => {

    const { disconnect, } = useContext(UserContext);

    //dropdown for transfer
    const [show, setShow] = useState(false);

    const reverseDropdown = e => {
        setShow(!show)
        setWalletShow(false);
    }

    //dropdown for my wallet
    const [walletShow, setWalletShow] = useState(false);

    const reverseWalletDropdown = e => {
        setWalletShow(!walletShow);
        setShow(false)
    }

    return (
        <div style={{ backgroundColor: 'white' }}>
            <Container className='dtc-navigation'>
                <Row className='d-flex align-items-center'>
                    <Col className='d-flex flex-row' xs={8} sm={8} md={8} lg={3}>
                        <Link to='/home' className='logo'>
                            <img src={Header} alt="" style={{ width: '100%' }} />
                        </Link>
                    </Col>
                    <Col md={9} className='d-none d-lg-block d-xl-block d-xxl-block  header-desktop'>
                        <a id="home" className="menu-item" href="/home">Home</a>
                        {/* <a id="contact" className="menu-item" href="/profile">Profile</a> */}
                        <a id="contact" className="menu-item" href="/my-dtcs">My DTCs</a>
                        <NavDropdown title="Transfer"
                            id="collasible-nav-dropdown"
                            show={show}
                            onClick={reverseDropdown}
                        >
                            <NavDropdown.Item href="/send">Send</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/receive">Receive</NavDropdown.Item>
                            
                            {/* <NavDropdown.Item id="about" href="/email">Encrypted Email</NavDropdown.Item> */}
                        </NavDropdown>
                        <NavDropdown title="My Wallet"
                            id="collasible-nav-wallet-dropdown"
                            show={walletShow}
                            onClick={reverseWalletDropdown}
                        >                        
                            <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/balance">Balance</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/agenda">Agenda</NavDropdown.Item>
                            {/* <NavDropdown.Divider />
                            <NavDropdown.Item href="/connect-wallet">Wallet connect</NavDropdown.Item> */}
                        </NavDropdown>
                        <label id="contact" className="send-button menu-item" onClick={async () => await disconnect()}>Logout</label>
                        <Installpwa></Installpwa>
                    </Col>
                    <Col className='burger-pwa d-xxl-none d-xl-none d-lg-none' xs={4} sm={4} md={4}>
                        <Menu right customBurgerIcon={<img src={Burger} alt='' />} >
                            <a id="home" className="menu-item" href="/home">Home</a>
                            <a id="contact" className="menu-item" href="/my-dtcs">My DTCs</a>
                            <a id="contact" className="menu-item" href="/send">Send</a>
                            <a id="about" className="menu-item" href="/receive">Receive</a>
                            <a id="about" className="menu-item" href="/view-friend-dtc">Search friend's Cards</a>
                            <a id="contact" className="menu-item" href="/balance">Balance</a>
                            <a id="contact" className="menu-item" href="/agenda">Agenda</a>
                            <a id="contact" className="menu-item" href="/profile">Profile</a>
                            {/* <a id="contact" className="menu-item" href="/connect-wallet">Connect wallet</a> */}
                            <label id="contact" className="menu-item" onClick={async () => await disconnect()}>Logout</label>
                        </Menu>
                        <Installpwa></Installpwa>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default Navigation