import React, { createContext, useState, useEffect } from 'react';
//imports
import { ethers } from 'ethers';
import axios from 'axios';
import Moralis from 'moralis';
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer'
import { pinJSONToIPFS, base64toBlob, sendJSONToIPFS, sendFileToIPFS } from '../utils/MakeNft/pinata'
import LogoDTC from '../assets/DTC-logo-2021.svg'

const contractABI = require('../utils/contracts/contract-abi.json')
const abi_1155 = require('../utils/contracts/erc1155.json');
const abi_721 = require('../utils/contracts/erc721.json');

const clientId = "BAgDqpVuQV4Zpy0aorPPcStnB4XtIfh6d8VcuVyiz_QwOqlEeF_RefKMnW86QKV6H_3akxIu3zDe2ceUeuny-OY"; //DTC WEB3
//const clientId = "BFeCaaVsFuu5nc5Uv3DlNS0pxfA_f5c61imkuvOOvrBDgDOYUkAQkfGhaKX9_1AH_OT8TOfLmSkW2JHwWTdDc4U"; //PIECEOFCAKE WEB3
export const contractAddress = "0x8b7855f52df16aef1a5f64b7016cea7b01ce876b";
// BFeCaaVsFuu5nc5Uv3DlNS0pxfA_f5c61imkuvOOvrBDgDOYUkAQkfGhaKX9_1AH_OT8TOfLmSkW2JHwWTdDc4U mainnet
//BC9PI90SL3fyek3bfCDNvoPwCCalWw9XX2N6pjHe4XmWkZR3USHgLu_CmgR-R37APrDP_3Uh9h-BQdSdZ2fGp-0 testnet
//borrar asdads

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  let navigate = useNavigate();
  // Cuenta de Metamask conectada
  const [currentAccount, setCurrentAccount] = useState("");
  const [ethersProvider, setEthersProvider] = useState('');
  const [sendAddress, setSendAddress] = useState('')
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState('')
  const [listOfNft, setListOfNft] = useState('')
  const [showImage, setShowImage] = useState(false);
  const [datosNft, setDatosNft] = useState('')
  const [contacts, setContacts] = useState([])
  const [balance, setBalance] = useState(0);
  const [balanceInMatic, setBalanceInMatic] = useState(0)
  const [priceUsdtMatic, setPriceUsdtMatic] = useState(0)
  const [transactions, setTransactions] = useState('')
  const [nftTransactions, setNftTransactions] = useState();
  //const [transactionOffset, setTransactionOffset] = useState(0);
  const [nfts, setNfts] = useState('')
  const [loader, setLoader] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [code, setCode] = useState('')
  const [transferError, setTransferError] = useState(false);
  const [privateKey, setPrivateKey] = useState('')
  const [templateId, setTemplateId] = useState('')
  const [refreshInput, setRefreshInput] = useState(false)
  const [errorFunds, setErrorFunds] = useState(false)
  const [params, setParams] = useState({})

  // useMoralis
  const { authenticate, isAuthenticated, isAuthenticating, account, enableWeb3, user, logout, isInitialized, provider } = useMoralis();
  const Web3Api = useMoralisWeb3Api();

  // Get Address
  const getEthAccount = async () => {
    if (isAuthenticated && user) {
      setCurrentAccount(user.get("ethAddress"));
      retrieveContacts(user.get("ethAddress"))
    }
  };

  //GET NFT OWNERS
  const fetchNFTOwners = async () => {
    const options = {
      address: currentAccount,
      chain: Moralis.Chains.POLYGON_MAINNET,
    };
    const NFTS = await Web3Api.account.getNFTs(options)
    
    setNfts(NFTS.total)


    setListOfNft(NFTS.result)

  };

  // Authenticate user in Moralis
  const connect = async () => {
    try {
      if (!isAuthenticated) {
        setLoader(true)
        //await Moralis.start({ appId: "DSJyS2AcMXSOLHApO8JnPZTRk5moEC6vsE9Srl3a", serverUrl: "https://7nmv6t4fk1od.usemoralis.com:2053/server" })
        await authenticate({
          provider: "web3Auth",
          clientId: clientId,
          chainId: Moralis.Chains.POLYGON_MAINNET,
          theme: "light",
          appLogo: LogoDTC,
          loginMethodsOrder: ["google", "facebook", "github", "discord", "linkedin"],
          signingMessage: "Connect to Perception Face Admin Panel"
        })
        setLoader(false)
        navigate('/home');
       
      }
    } catch (error) {
      console.log(error);
      throw new Error("Error en el login: " + error.message)
    }
  };

  // Authenticate user in Moralis
  const connectClaim = async () => {
    try {
      if (!isAuthenticated) {
        //await Moralis.start({ appId: "DSJyS2AcMXSOLHApO8JnPZTRk5moEC6vsE9Srl3a", serverUrl: "https://7nmv6t4fk1od.usemoralis.com:2053/server" })
        await authenticate({
          provider: "web3Auth", 
          clientId: clientId,
          chainId: Moralis.Chains.POLYGON_MAINNET,
          theme: "light",
          appLogo: LogoDTC,
          loginMethodsOrder: ["google", "facebook", "github", "discord", "linkedin"],
          signingMessage: "Connect to Perception Face Admin Panel"
        })
        navigate('/home');
      }
    } catch (error) {
      console.log(error);
      throw new Error("Error en el login: " + error.message)
    }
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      checkUser()
      if (Object.keys(params).length !== 0){
        checkParams()
      }
    }
    
  },[isAuthenticated])

  async function checkUser(){
    const userInfo = await getUserInfo(user)
    if(userInfo.attributes.emailLogged === undefined){
      await setUserInfo()
      navigate('/firstlogin')
    }
    else {
      console.log("already registered")
    }
  }

  async function setUserInfo(){
    const logInInfo = JSON.parse(localStorage.getItem("openlogin_store"))

    Moralis.Cloud.run('SetUserInfo', {name: logInInfo.name, emailLogged: logInInfo.email, typeOfLogin: logInInfo.typeOfLogin, userId: user.id})
    .then(response => { console.log(response) })
  }

  async function getUserInfo(user) {
    const result = await Moralis.Cloud.run('GetUserInfo', { userId: user.id })
    return result
  }


  async function transferFunds(nft){
    const signer = ethersProvider.getSigner();
    const relayer = "0x108ee0dc10fefc69be3d13bf3831498edd380bd1"
    const fastestGas = await getMaxGas();
    let estimatedGasLimit;
    let contract

    if (nft.contract_type === "ERC1155"){
      contract = new ethers.Contract(nft.token_address, abi_1155, signer);
      estimatedGasLimit = (await contract.estimateGas.safeTransferFrom(user.attributes.ethAddress, relayer, nft.token_id, 1, "0x00")).toString()    //const bgNumber = ethers.BigNumber.from(_tokenId);
    }
    else if (nft.contract_type === "ERC721") {
      contract = new ethers.Contract(nft.token_address, abi_721, signer);
      estimatedGasLimit = (await contract.estimateGas.safeTransferFrom(user.attributes.ethAddress, relayer, nft.token_id)).toString()
    }
    const estimatedGasCost = ethers.utils.parseUnits((ethers.utils.formatUnits((fastestGas.toString()*2), "gwei") * (estimatedGasLimit.toString())).toFixed(6), 9).toString()

    const tx = await signer.sendTransaction({
      to: relayer,
      value: estimatedGasCost,
      gasPrice: fastestGas
    })

    return tx.wait();

  }

  async function approveRelayer(nft){
      //   await approveRelayer(contract, relayer)
      const relayer = "0x108ee0dc10fefc69be3d13bf3831498edd380bd1"
      const fastestGas = await getMaxGas();
      const signer = ethersProvider.getSigner();
      let contract
      if (nft.contract_type === "ERC1155"){
        contract = new ethers.Contract(nft.token_address, abi_1155, signer);
      }
      else if (nft.contract_type === "ERC721") {
        contract = new ethers.Contract(nft.token_address, abi_721, signer);
      }
      if(!(await contract.isApprovedForAll(user.attributes.ethAddress, relayer))){
        const tx = await contract.setApprovalForAll(relayer, true, {gasPrice: fastestGas});
        await tx.wait();

      } else {
        console.log("already approved")
      }   
  }


  async function checkParams(){
    setLoader(true)
    
    console.log(params)
    if (params.id) {
      const transfer = await Moralis.Cloud.run("DtcTransferByRelayer", {id: params.id, to: user.attributes.ethAddress})
      console.log(transfer)
      
    }
    else if (params.templateId && params.listId && params.recordId){
      console.log("runnung TEST")
      const mint = await Moralis.Cloud.run("Test", {
        listId: params.listId,
        templateId: params.templateId,
        recordId: params.recordId,
        to: user.attributes.ethAddress
      })
      console.log(mint)
    }
    else {
      console.log("no params")
    }
    setLoader(false)
  }
  
  useEffect(() => {
    if (provider) {
      const newProvider = new ethers.providers.Web3Provider(provider);

      setEthersProvider(newProvider);
    }
    
  }, [provider])
  
  const enableWeb3Auth = async () => {
    try {
      if (!isAuthenticating && isAuthenticated && !account) {
        await enableWeb3({
          provider: 'web3Auth',
          clientId: clientId,
          chainId: Moralis.Chains.POLYGON_MAINNET,
        });
      }
    } catch (error) {
      throw new Error("error activando web3")
    }
  }
  
  
  const disconnect = async () => {

    setCurrentAccount('');
    localStorage.clear()
    await logout();
    window.location.reload()
  };
  
  useEffect(() => {
    getEthAccount();
  }, [isAuthenticated, isInitialized]);
  
  useEffect(() => {
    enableWeb3Auth();
  }, [isAuthenticated, isAuthenticating, account])
  
  useEffect(() => {
    fetchNFTOwners();
    fetchNativeBalance();
    fetchTransactions(currentAccount);
    //fetchTransactions(contractAddress);
    fetchTokenPrice()
  }, [ethersProvider]);
  
  /*useEffect(() => {
    // fetchTransactions(transactionOffset, 10, currentAccount);
    fetchTransactions(transactionOffset, 10, currentAccount);
    fetchTransactions(transactionOffset, 10, contractAddress);
  }, [ethersProvider, transactionOffset]);*/
  
  
  const mintNFT = async (url, name, description, attributes, amount, currentAccount) => {
    setLoader(true)
    try {  
      const obj = {}
      obj.imageBase64 = image
      console.log(obj)
      const json = JSON.stringify(obj)
      // sendJSONToIPFS(json)
      const response = await sendFileToIPFS(base64toBlob(image, "image/png"))
      const shortResponse = "https://ipfs.io/ipfs/" + response.split("/")[4]
      console.log(shortResponse)
      
      if (response && shortResponse && name && description) {
        console.log('llega a onMint')
      }
      
      console.log( name)
      if (!ethersProvider) {
        //console.log("provider not initialized yet");
        return;
      }

      

      const metadata = {name: name, image: response, description: description, attributes: attributes};
      console.log(metadata);
      
      const pinataResponse = await pinJSONToIPFS(metadata);
      if (!pinataResponse.success) {
        return {
          success: false,
          status: "😢 Something went wrong while uploading your tokenURI.",
        }
      } 
      const tokenURI = pinataResponse.pinataUrl;  
      console.log("TOKEN URI PINATA", tokenURI)
      const shortResponsePinata = "https://ipfs.io/ipfs/" + tokenURI.split("/")[4]
      console.log(shortResponsePinata)
      
      console.log('entra create')
      const signer = ethersProvider.getSigner();
      let fastestGas = await getMaxGas();
      // Instancia el contrato
      let contract = new ethers.Contract(contractAddress, contractABI, signer);
      const tx = await contract.createCollectionAndMint(currentAccount, name, amount, shortResponsePinata, "0x",  { gasPrice: fastestGas });
      await tx.wait()
      setLoader(false)
      console.log('nft creado')
      setOpenConfirmationModal(true);
    } catch (error) {
      console.error('Error minteando el NFT: ', error);
      setLoader(false)
      setTransferError(true)
    }
    
  }
  
  
  const Transfer = async (toAddress, toAmount) => {
    setLoader(true)
 
    if (!ethersProvider) {
      
      return;
    }
    
    let fastestGas = await getMaxGas();
    try{
      const signer = ethersProvider.getSigner();
      await signer.sendTransaction({
        to: toAddress,
        value: (toAmount * 10 ** 18).toString(),
        gasPrice: fastestGas,
      });
      setLoader(false)
      setOpenConfirmationModal(true)
    } catch(error) {
      console.error('Error transfiriendo el NFT: ', error);
      setLoader(false)
      setTransferError(true)
    }
    //setLoader(false)
    //setOpenConfirmationModal(true)
  };
  
  //consigue la cantidad maxima de tokens MATIC sin contar el gas  
  const getMaxTokensAndGas = async () => {
    const gasFees = ethers.utils.formatEther( await getMaxGas() )
    const maticWithoutGas = Number(balanceInMatic) - Number(gasFees)
    return maticWithoutGas;
  } 
  
  
  // transfererimos nft
  const transfer_nft_1155 = async (_address, _tokenId, _from, _to, _amount) => {
    setLoader(true)
    try {  
    
     
      const signer = ethersProvider.getSigner();
      // Instancia el contrato
      let contract = new ethers.Contract(_address, abi_1155, signer);
      let fastestGas = await getMaxGas();
      //const bgNumber = ethers.BigNumber.from(_tokenId);
      const tx = await contract.safeTransferFrom(_from, _to, _tokenId, _amount, "0x",  { gasPrice: fastestGas });
      await tx.wait()
      
      setOpenConfirmationModal(true)
    } catch (error) {
      console.error('Error transfiriendo el NFT: ', error);
      setErrorFunds(true)
      
    }
    setLoader(false)
  }
  
  const transfer_nft_721 = async (_address, _tokenId, _from, _to) => {
    setLoader(true)
    try {
      

      const signer = ethersProvider.getSigner();
      // Instancia el contrato
      let contract = new ethers.Contract(_address, abi_721, signer);
      let fastestGas = await getMaxGas();
      //const bgNumber = ethers.BigNumber.from(_tokenId);
      const tx = await contract.safeTransferFrom(_from, _to, _tokenId, { gasPrice: fastestGas });
      await tx.wait()
      
      setOpenConfirmationModal(true)
    } catch (error) {
      console.error('Error transfiriendo el NFT: ', error);
      setErrorFunds(true)
      
    }
    setLoader(false)
  }
  
  
  const getMaxGas = async () => {
    let feeData = await axios.get('https://gasstation-mainnet.matic.network/');
    let fastestGas = ethers.utils.parseUnits((feeData.data.fastest).toString(), "gwei");
    
    return fastestGas
  }
  
  
  // GET CONTACTS FROM DB 
  async function retrieveContacts(currentAccount) {
    await Moralis.Cloud.run('RetrieveContacts', { wallet: currentAccount })
    .then(response => { setContacts(response) })
  }
  
  // DELETE CONTACTS FROM DB 
  async function deleteContact(id) {
    await Moralis.Cloud.run('RemoveContact', { id })
    .then(response => { console.log(response) })
  }
  
  // GET BALANCE
  const fetchNativeBalance = async () => {
    // get mainnet native balance for the current user
    const options = {
      address: currentAccount,
      chain: Moralis.Chains.POLYGON_MAINNET,
    };
    const balance = await Web3Api.account.getNativeBalance(options);
    
    setBalance(balance);
    const maticValue = ethers.utils.formatEther(balance.balance);
    setBalanceInMatic(maticValue);
  };
  
  const InputDataDecoder = require("ethereum-input-data-decoder");
  const abi_1155Balance = require("../utils/contracts/erc1155Balance.json");
  // GET transactions
  const fetchTransactions = async (/*_offset, _limit, */ maticAdrress) => {
    /*console.log(_offset)
    console.log(_limit)*/
    // _offset: (Number(page - 1) * limit), _limit: limit
    // traemos las transacciones
    const options = {
      address: maticAdrress,
      chain: Moralis.Chains.POLYGON_MAINNET,
      //offset: _offset,
      //limit: _limit,
    };
    const newTransactions = await Web3Api.account.getTransactions(options);

    for (const transaction of newTransactions.result) {
      if(transaction?.to_address !== contractAddress) {
        const decoder = new InputDataDecoder(abi_721);
        const data = transaction.input;
        const result = decoder.decodeData(data);
        let addressTo = null;
        switch (result.method) {
          case "createCollectionAndMint":
            addressTo = `0x${result.inputs[1]}`;
            break;
          case "safeTransferFrom":
            addressTo = `0x${result.inputs[1]}`;
            break;
          case "setApprovalForAll":
            break;
          case "grantRole":
            addressTo = `0x${result.inputs[1]}`;
            break;
          case "revokeRole":
            addressTo = `0x${result.inputs[1]}`;
            break;
          default:
            addressTo = transaction.to_address;
            break;
        }
        const user = await Moralis.Cloud.run("GetUserInfoByAddress", {
          address: addressTo ? addressTo.toLowerCase() : "",
        });
        transaction.result = result;
        if (user) {
          transaction.email = user.attributes.emailLogged;
        }
      }else{
        const decoder = new InputDataDecoder(abi_1155Balance);
        const data = transaction.input;
        const result = decoder.decodeData(data);
        let addressTo = null;
        switch (result.method) {
          case "createCollectionAndMint":
            addressTo = `0x${result.inputs[1]}`;
            break;
          case "safeTransferFrom":
            addressTo = `0x${result.inputs[1]}`;
            break;
          case "setApprovalForAll":
            break;
          case "grantRole":
            addressTo = `0x${result.inputs[1]}`;
            break;
          case "revokeRole":
            addressTo = `0x${result.inputs[1]}`;
            break;
          default:
            addressTo = transaction.to_address;
            break;
        }
        const user = await Moralis.Cloud.run("GetUserInfoByAddress", {
          address: addressTo ? addressTo.toLowerCase() : "",
        });
        transaction.result = result;
        if (user) {
          transaction.email = user.attributes.emailLogged;
        }
      }
    }
    if (maticAdrress === currentAccount) {
      setTransactions(newTransactions);
      //console.log("Wallet Txs (" + maticAdrress + ")", newTransactions);
    } else {
      setNftTransactions(newTransactions);
      //console.log("Contract Txs (" + maticAdrress + ")", newTransactions);
    }
    //return newTransactions;
  };
  
  //PRIVATE KEY
  //Assuming user is already logged in.
  async function getPrivateKey() {
    const privateKey = await ethersProvider.provider.request({
      method: "eth_private_key"
    });
    
    //Do something with privateKey
    setPrivateKey(privateKey)
  }
  
  
  //GET MATIC PRICE IN USD
  const fetchTokenPrice = async () => {
    //Get token price on PancakeSwap v2 BSC
    const options = {
      address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
      chain: Moralis.Chains.POLYGON_MAINNET,
      exchange: "quickswap",
    };
    const price = await Web3Api.token.getTokenPrice(options);
    
    setPriceUsdtMatic(price)
  };
  
  useEffect(() => {
    getPrivateKey()
    
  }, [ethersProvider]);
  
  // IDLE DETECTOR FOR USER STATUS ONLINE AND LOGOUT
  const timeout = 900000
  const [remaining, setRemaining] = useState(timeout)
  const [elapsed, setElapsed] = useState(0)
  const [isIdle, setIsIdle] = useState(false)
  const handleOnActive = () => {
    setIsIdle(false)
    
  }
  const handleOnIdle = async() => {
    setIsIdle(true)
    await disconnect();
    alert('You have been inactive for 15 minutes, please Log in again.')
  }
  
  const {
    getRemainingTime,
    getElapsedTime
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  })
  
  useEffect(() => {
    setRemaining(getRemainingTime())
    setElapsed(getElapsedTime())
    setInterval(() => {
      setRemaining(getRemainingTime())
      setElapsed(getElapsedTime())
    }, 1000)
  }, [])
  
  //CAMERA FUNCTIONS
  const handleChange = (image) => {
    setLoader(true)
    const data = image.target.files[0];
    getBase64(data)
  }
  
  const getBase64 = file => {
    let reader = new FileReader();
    if (file) reader.readAsDataURL(file)
    reader.onload = () => {
      onLoad(reader.result);
    };
  };
  
  const onLoad = fileString => {
    setImage(fileString)
    navigate("/make-dtc/make")
    
  };
  
  
  return (
    <UserContext.Provider value={{
      connect,
      logout,
      user,
      provider,
      disconnect,
      Transfer,
      transfer_nft_721,
      transfer_nft_1155,
      nfts,
      image,
      setImage,
      showImage,
      setShowImage,
      isAuthenticated,
      ethersProvider,
      currentAccount,
      getEthAccount,
      fetchNFTOwners,
      listOfNft,
      datosNft,
      contacts,
      retrieveContacts,
      balance,
      nftTransactions,
      balanceInMatic,
      priceUsdtMatic,
      transactions,
      loader,
      setLoader,
      timeout,
      remaining,
      elapsed,
      getMaxTokensAndGas,
      isIdle,
      isVisible, 
      setIsVisible,
      mintNFT,
      setOpenConfirmationModal,
      openConfirmationModal,
      deleteContact,
      code,
      setCode,
      //setTransactionOffset,
      connectClaim,
      sendAddress,
      setSendAddress,
      approveRelayer,
      transferFunds,
      transferError,
      handleChange,
      privateKey,
      setTemplateId,
      refreshInput,
      setRefreshInput,
      errorFunds,
      setParams,
      params
    }}>
      {children}
    </UserContext.Provider>
  )
}
