
import React, { useContext } from 'react';
import Modal from 'react-modal';
import "./modal.css";
import { useNavigate } from "react-router-dom";
import GridLoader from "react-spinners/GridLoader";
import { useWalletConnectClient } from '../../context/walletConnectContext'
// context
import { UserContext } from '../../context/userContext'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


function Modales({ _to, _amount, _from, _token_id, _token_address, _function, _icon, _title, _text, _url, _name, _description, _attributes, _emailTo, _currentAccount, _id, modalToParent, action, _nft, _transactionData }) {

  /* walletConnect */
  const { accept_transaction, reject_transaction } = useWalletConnectClient();

  let subtitle;

  const [modalIsOpen, setIsOpen] = React.useState(true);

  const { Transfer, transfer_nft_721, transfer_nft_1155, mintNFT, deleteContact, setOpenConfirmationModal } = useContext(UserContext)
  const navigate = useNavigate();


  function afterOpenModal() {
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
    modalToParent();
  }

  function closeModalCreate() {
    setOpenConfirmationModal(false)
    setIsOpen(false);
    modalToParent();
    navigate("/home")
    window.location.reload()
  }

  // let navigate = useNavigate();

  async function handleClick() {
    if (_function === "Transfer") {
      Transfer(_to, _amount);
    }
    if (_function === "transfer_nft_721") {
      transfer_nft_721(_token_address, Number(_token_id), _from, _to);
    }
    if (_function === "transfer_nft_1155") {
      transfer_nft_1155(_token_address, Number(_token_id), _from, _to, _amount);
    }
    if (_function === "Create") {
      mintNFT(_url, _name, _description, _attributes, _amount, _currentAccount);
    }
    if (_function === 'Delete_Contact') {
      deleteContact(_id);
      setOpenConfirmationModal(true)
    }
    closeModal();
    //window.location.reload()
  }

  const handle_transaction = async (_accept) => {
    try {
      if (!_accept) await reject_transaction();
      else await accept_transaction();
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='modal-base'>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        {(_icon === "no") ? null : (_icon === "check") ? (<i className="fa-regular fa-circle-check" color='#2A0173'></i>) : (_icon === 'cancel') ? (<i className="fa-regular fa-circle-xmark"></i>) : null}

        {(_title !== "" && _title !== undefined) ? (
          <h2 ref={(_subtitle) => (subtitle = _subtitle)} >{_title}</h2>
        ) : (
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Successfully {action}!</h2>
        )}
        <p style={{ whiteSpace: "pre-line" }}>
          {_text !== "" ? (_text) : ("footer_banner--googleBanner--3dAQe footer_banner--banner--H-B_t modal--modal--OAB3b modal--modalShadow--HbHkT modal--modalBare--2SeY8")
          }
        </p>
        {_function === 'Create' &&
          <>
            <p style={{ whiteSpace: "pre-line" }}>
              <label style={{ fontWeight: "bold" }}>NFT: </label> &nbsp; {_name}
            </p>
            <p style={{ whiteSpace: "pre-line" }}>
              <label style={{ fontWeight: "bold" }}>Supply: </label> &nbsp; {_amount}
            </p>
            <div className='buttons'>
              <button className='cancel-button' onClick={closeModal}>No</button>
              <button className='accept-button' onClick={handleClick}>Yes</button>
            </div>
          </>
        }
        {_function === 'Transfer' &&
          <>
            <p >
              <label style={{ fontWeight: "bold", color: 'black' }}>Amount: </label> &nbsp; {_amount} Matic.
            </p>
            <p >
              <label style={{ fontWeight: "bold", color: 'black' }}>Address: </label> &nbsp; {_to.slice(0, 5)}...{_to.slice(37)}
            </p>
            <div className='buttons'>
              <button className='cancel-button' onClick={closeModal}>No</button>
              <button className='accept-button' onClick={handleClick}>Yes</button>
            </div>
          </>
        }

        {(_function === "transfer_nft_1155" || _function === "transfer_nft_721") &&
          <>
            <p style={{ whiteSpace: "pre-line" }}>
              <label style={{ fontWeight: "bold" }}>NFT: </label> &nbsp; {_name}
            </p>
            <p style={{ whiteSpace: "pre-line" }}>
              <label style={{ fontWeight: "bold" }}>Address: </label> &nbsp; {_to.slice(0, 5)}...{_to.slice(37)}
            </p>
            <div className='buttons'>
              <button className='cancel-button' onClick={closeModal}>No</button>
              <button className='accept-button' onClick={handleClick}>Yes</button>
            </div>
          </>
        }

        {_function === "Delete_Contact" &&
          <>

            <div className='buttons'>
              <button className='cancel-button' onClick={closeModal}>No</button>
              <button className='accept-button' onClick={handleClick}>Yes</button>
            </div>
          </>
        }

        {_function === "sendViaEmail" &&
          <>
            <p style={{ whiteSpace: "pre-line" }}>
              <label style={{ fontWeight: "bold" }}>NFT: </label> &nbsp; {_name}
            </p>
            <p style={{ whiteSpace: "pre-line" }}>
              <label style={{ fontWeight: "bold" }}>Email: </label> &nbsp; {_to}
            </p>
          </>
        }
        {_function === "processing" &&
          <>
            <GridLoader color='#E9E9E9' style={{ marginBottom: "2rem" }} />
            <p style={{ whiteSpace: "pre-line", fontWeight: 'bold', marginTop: "2rem" }}>
              {_name}
            </p>
            <p style={{ whiteSpace: "pre-line", marginTop: "1rem", marginBottom: '1rem', fontWeight: '700' }}>
              <label style={{ fontWeight: "700" }}>{_description} </label> &nbsp;
            </p>
          </>
        }


        {_function === "wallet_connect" &&
          <>
            <p style={{ whiteSpace: "pre-line" }}>
              <label style={{ fontWeight: "bold" }}>Method: </label> &nbsp; {_transactionData.request.method} 
            </p>
            <div className='buttons'>
              <button className='cancel-button' onClick={() => handle_transaction(false)}>Reject</button>
              <button className='accept-button' onClick={() => handle_transaction(true)}>Sign</button>
            </div>
          </>
        }


        {(_function === "" || _function === undefined) && (
          <div className='buttons'>
            <button className='done-button' variant='outlined' onClick={closeModalCreate}>Done</button>
          </div>
        )}

      </Modal>
    </div>
  );
}

export default Modales;